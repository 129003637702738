import React, { useState } from 'react';
import {
	Box,
	Flex,
	Button,
	useColorModeValue,
	Container,
	Link,
	Image,
	IconButton,
	Drawer,
	DrawerBody,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	useDisclosure,
	Text,
	useBreakpointValue,
	Stack,
	Divider,
} from '@chakra-ui/react';
import { RxHamburgerMenu } from "react-icons/rx";
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { SignedIn, SignedOut, UserButton, useUser } from '@clerk/clerk-react';

import DownloadApp from './Home/DownloadApp';

const Navbar = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [placement, setPlacement] = useState('right');
	const [triggerOpen, setTriggerOpen] = useState(false);

	const btnRef = React.useRef();
	const navigate = useNavigate();
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const { user } = useUser();

	const linkColor = useColorModeValue("#2e0a37", "#2e0a37");
	const bgColor = useColorModeValue('rgba(255, 255, 255, 0.95)', 'rgba(26, 32, 44, 0.95)'); // Slight transparency for floating effect
	const boxShadow = useColorModeValue('sm', 'sm-dark'); // Optional: different shadow for light/dark modes

	return (
		<>
			<DownloadApp triggerOpen={triggerOpen} setTriggerOpen={setTriggerOpen} />
			<Box
				position="fixed"
				top="2%"
				left="auto"
				width="80%"
				bg={bgColor}
				px={4}
				borderRadius={isMobile ? "0" : "full"}
				boxShadow={boxShadow}
				zIndex="1000"
			>
				<Container maxW="container.xl">
					<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
						<Box
							display={'flex'}
							flexDir={"row"}
							alignItems={"center"}
							onClick={() => navigate("/")}
							cursor="pointer"
						>
							<Image src="/assets/images/logo.png" alt="Nesa Mobile" h="50px" />
							<Text fontWeight={"bold"} color={"#2e0a37"} _hover={{ cursor: "pointer" }}>
								Nesa
							</Text>
						</Box>

						<Flex alignItems={'center'}>
							<IconButton
								ref={btnRef}
								icon={<RxHamburgerMenu />}
								display={{ base: 'flex', lg: 'none' }}
								onClick={onOpen}
								size="md"
								aria-label={'Open Menu'}
								fontSize="20px"
								variant="ghost"
								color={linkColor}
							/>
							<Flex
								display={{ base: 'none', lg: 'flex' }}
								alignItems="center"
								gap={1}
							>
								<Flex gap={1}>
									<Button
										variant='ghost'
										as={ReactRouterLink}
										to='/plans'
										color={linkColor}
										size="sm"
										_hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
									>
										Destinations
									</Button>
									{/* <Button
										variant='ghost'
										as={ReactRouterLink}
										to='/faq'
										color={linkColor}
										size="sm"
										_hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
									>
										How It Works
									</Button> */}
									{/* <Button
										variant='ghost'
										as={ReactRouterLink}
										to='/partners'
										color={linkColor}
										size="sm"
										_hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
									>
										For Business
									</Button> */}
									<Button
										variant='ghost'
										as={ReactRouterLink}
										to='/contact'
										color={linkColor}
										size="sm"
										_hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
									>
										Support
									</Button>
								</Flex>

								<Box
									height="24px"
									borderLeft="1px"
									borderColor={useColorModeValue('gray.200', 'gray.700')}
									mx={2}
								/>

								<Flex gap={1} alignItems="center">
									<SignedIn>
										<Link
											as={ReactRouterLink}
											to="/dashboard"
											p={2}
											display="block"
											color={linkColor}
											fontWeight="bold"
											onClick={onClose}
										>
											Dashboard
										</Link>
										<Flex p={2} align="center" gap={2}>
											<UserButton afterSignOutUrl="/" />
										</Flex>
									</SignedIn>
									<SignedOut>
										<Button
											variant='ghost'
											color={'white'}
											size="sm"
											_hover={{ bg: '#11151c' }}
											background={'#1A202C'}
											onClick={() => {
												setTriggerOpen(true);
											}}
										>
											Download
										</Button>
										<Button
											variant='ghost'
											as={ReactRouterLink}
											to='/sign-in'
											color={linkColor}
											size="sm"
											_hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
										>
											Sign in
										</Button>
									</SignedOut>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Container>

				<Drawer
					isOpen={isOpen}
					placement={placement}
					onClose={onClose}
					finalFocusRef={btnRef}
				>
					<DrawerOverlay />
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader>Menu</DrawerHeader>

						<DrawerBody>
							<Stack spacing={4}>
								<Link
									as={ReactRouterLink}
									to="/"
									p={2}
									display="block"
									color={linkColor}
									fontWeight="bold"
									onClick={onClose}
								>
									Home
								</Link>
								<Link
									as={ReactRouterLink}
									to="/plans"
									p={2}
									display="block"
									color={linkColor}
									fontWeight="bold"
									onClick={onClose}
								>
									Destinations
								</Link>
								{/* <Link
									as={ReactRouterLink}
									to="/faq"
									p={2}
									display="block"
									color={linkColor}
									fontWeight="bold"
									onClick={onClose}
								>
									How It Works
								</Link> */}
								{/* <Link
									as={ReactRouterLink}
									to="/partners"
									p={2}
									display="block"
									color={linkColor}
									fontWeight="bold"
									onClick={onClose}
								>
									For Business
								</Link> */}
								<Link
									as={ReactRouterLink}
									to="/contact"
									p={2}
									display="block"
									color={linkColor}
									fontWeight="bold"
									onClick={onClose}
								>
									Support
								</Link>

								<Divider />

								<SignedIn>

									<Link
										as={ReactRouterLink}
										to="/dashboard"
										p={2}
										display="block"
										color={linkColor}
										fontWeight="bold"
										onClick={onClose}
									>
										Dashboard
									</Link>
									<Flex p={2} align="center" gap={2}>
										<UserButton afterSignOutUrl="/" />
										<Text color={linkColor} fontWeight="medium">
											{user?.firstName} {user?.lastName}
										</Text>
									</Flex>
								</SignedIn>
								<SignedOut>
									<Link
										as={ReactRouterLink}
										to="/sign-in"
										p={2}
										display="block"
										color={linkColor}
										fontWeight="bold"
										onClick={onClose}
									>
										Sign in
									</Link>
									<Link
										as={ReactRouterLink}
										to="/sign-up"
										p={2}
										display="block"
										color="white"
										fontWeight="bold"
										bg="purple.500"
										_hover={{ bg: 'purple.600' }}
										borderRadius="md"
										onClick={onClose}
									>
										Sign up
									</Link>
									<Link
										p={2}
										display="block"
										color={'white'}
										fontWeight="bold"
										_hover={{ bg: '#11151c' }}
										background={'#1A202C'}
										onClick={() => {
											setTriggerOpen(true);
											onClose();
										}}
									>
										Download App
									</Link>
								</SignedOut>
							</Stack>
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</Box>
		</>
	);
};

export default Navbar;
