import React from 'react';
import { Box, Flex, Heading, Text, Image, VStack } from '@chakra-ui/react';

import BackgroundShape from '../General/BackgroundShape';

import './StepsSection.css';

const StepsSection = () => (
  <BackgroundShape type="wave">
    <Box py="20" className="steps-section">
      <Flex direction="column" align="center" maxW="1200px" mx="auto">
        <Heading size="xl" p="5" mb="5">Getting Connected is Easy</Heading>
        <Text fontSize="lg" color="white" textAlign="center" maxW="800px" mb="10">
          Getting started is easy, first download the app and follow these simple steps to get connected.
        </Text>
        <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" w="100%">
          <Box flex="1" textAlign="center" p="5">
            <Image src="/assets/images/screenshots/screenshot_1.png" alt="Step 1" maxW="200px" mx="auto" />
            <Heading size="md" mt="4">Pick a Country</Heading>
            <Text mt="2">Pick your travel destination from our extensive list of 185+ countries.</Text>
          </Box>
          <Box flex="1" textAlign="center" p="5">
            <Image src="/assets/images/screenshots/screenshot_2.png" alt="Step 2" maxW="200px" mx="auto" />
            <Heading size="md" mt="4">Select Your Plan</Heading>
            <Text mt="2">Find the perfect data plan that suits your needs and budget.</Text>
          </Box>
          <Box flex="1" textAlign="center" p="5">
            <Image src="/assets/images/screenshots/screenshot_3.png" alt="Step 3" maxW="200px" mx="auto" />
            <Heading size="md" mt="4">Activate Instantly</Heading>
            <Text mt="2">Activate your eSIM in minutes and start using data right away.</Text>
          </Box>
          <Box flex="1" textAlign="center" p="5">
            <Image src="/assets/images/screenshots/screenshot_4.png" alt="Step 3" maxW="200px" mx="auto" />
            <Heading size="md" mt="4">Enjoy Your Data</Heading>
            <Text mt="2">Enjoy seamless connectivity and stay in touch wherever you go.</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  </BackgroundShape>
);

export default StepsSection;
