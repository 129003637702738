import axios from 'axios';
import { openDB } from 'idb';

import countryUtils from '../Plans/country';

class ApiClient {
  constructor(baseURL, getToken, cacheDuration = 1200000) { // cacheDuration in milliseconds, default 20 minutes
    this.baseURL = baseURL;
    this.getToken = getToken; // Will now receive Clerk token
    this.cacheDuration = cacheDuration; // Duration for which the cache is valid
    this.client = axios.create({
      baseURL: this.baseURL,
    });

    // Attempt to open IndexedDB, fallback to in-memory cache if not supported
    this.initDB();
    this.inMemoryCache = {};

    // Update interceptor to use Clerk token
    this.client.interceptors.request.use(
      async (config) => {
        const token = await this.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async initDB() {
    try {
      this.db = await openDB('api-cache', 1, {
        upgrade(db) {
          db.createObjectStore('responses', { keyPath: 'key' });
        },
      });
      this.useIndexedDB = true;
    } catch (error) {
      console.warn('IndexedDB is not supported, falling back to in-memory cache');
      this.useIndexedDB = false;
    }
  }

  async isCacheValid(cacheKey) {
    if (this.useIndexedDB) {
      const cached = await this.db.get('responses', cacheKey);
      if (!cached) return false;

      const now = new Date().getTime();
      return (now - cached.timestamp) < this.cacheDuration;
    } else {
      const cached = this.inMemoryCache[cacheKey];
      if (!cached) return false;

      const now = new Date().getTime();
      return (now - cached.timestamp) < this.cacheDuration;
    }
  }

  async get(endpoint, params = {}, shouldCache = true) {
    await this.initDB();
    const cacheKey = `${endpoint}-${JSON.stringify(params)}`;

    if (shouldCache) {
      if (await this.isCacheValid(cacheKey)) {
        if (this.useIndexedDB) {
          const cached = await this.db.get('responses', cacheKey);
          return Promise.resolve({ data: cached.data });
        } else {
          return Promise.resolve({ data: this.inMemoryCache[cacheKey].data });
        }
      }
    }

    try {
      const response = await this.client.get(endpoint, { params });

      if (response.status === 200) {
        // Normalize the response data if it is the plans endpoint
        if (endpoint === '/plans') {
          response.data.obj.packageList = countryUtils.sortLocationInPackages(response.data.obj.packageList);
        }
      }

      const cacheEntry = {
        key: cacheKey,
        data: response.data,
        timestamp: new Date().getTime()
      };

      if (shouldCache) {
        if (this.useIndexedDB) {
          await this.db.put('responses', cacheEntry);
        } else {
          this.inMemoryCache[cacheKey] = cacheEntry;
        }
      }

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async post(endpoint, data = {}) {
    const response = await this.client.post(endpoint, data);
    // Invalidate the cache for this endpoint on a POST request
    await this.invalidateCache(endpoint);
    return response;
  }

  async put(endpoint, data = {}) {
    const response = await this.client.put(endpoint, data);
    // Invalidate the cache for this endpoint on a PUT request
    await this.invalidateCache(endpoint);
    return response;
  }

  async delete(endpoint) {
    const response = await this.client.delete(endpoint);
    // Invalidate the cache for this endpoint on a DELETE request
    await this.invalidateCache(endpoint);
    return response;
  }

  async invalidateCache(endpoint) {
    if (this.useIndexedDB) {
      const keys = await this.db.getAllKeys('responses');
      keys.forEach(async key => {
        if (key.startsWith(endpoint)) {
          await this.db.delete('responses', key);
        }
      });
    } else {
      Object.keys(this.inMemoryCache).forEach(key => {
        if (key.startsWith(endpoint)) {
          delete this.inMemoryCache[key];
        }
      });
    }
  }
}

export default ApiClient;
