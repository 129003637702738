import { useState } from 'react';
import {
  Box, Flex, Heading, Text, Button, Image, Center, Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import HeroSection from '../General/HeroSection';
import DownloadApp from './DownloadApp';


const MainSection = () => {

  const [triggerOpen, setTriggerOpen] = useState(false);

  const navigate = useNavigate();
  return (
    <HeroSection>
      <DownloadApp triggerOpen={triggerOpen} setTriggerOpen={setTriggerOpen} />
      <Box py="20">
        <Flex direction={{ base: 'column-reverse', md: 'row' }} align="center" maxW="1200px" mx="auto">
          <Box flex="1" p="5">
            <Heading size="xl" mb="4">Travel, Work, Adventure.<br />Stay Connected in Seconds.</Heading>
            <Text fontSize="md" mb="4">
              Join the next generation of global travelers who refuse to pay roaming fees or fumble with local SIM cards.
            </Text>

            <Box width={'100%'} mt={'10'}>
              <Flex direction={'column'} gap="4" alignItems={'center'}>
                <Button colorScheme="purple" width={'65%'} borderRadius={'5rem'} size="lg" onClick={() => {

                  setTriggerOpen(true);
                }} >Get Started</Button>
                <Button borderRadius={'5rem'} width={'45%'} bgColor={'gray.800'} onClick={() => { navigate('/plans') }}>View Plans</Button>
              </Flex>
            </Box>

            {/* <Flex>
              <Image src="/assets/images/apple_app_store.png" alt="Download on the App Store" mr="4" width="120px" style={{
                cursor: "pointer"
              }} onClick={() => {
                window.open("https://apps.apple.com/app/nesa-affordable-global-data/id6504453237");
              }} />
              <Image src="/assets/images/google_play_store.png" alt="Get it on Google Play" width="120px" style={{
                cursor: "pointer"
              }} onClick={() => {
                window.open("https://play.google.com/store/apps/details?id=com.nesamobile.app");
              }} />
            </Flex> */}
          </Box>
          <Box flex="1">
            <Center>
              <Image src="/assets/images/hero_image.png" alt="Main" maxW={"75%"} />
            </Center>
          </Box>
        </Flex>
      </Box>
    </HeroSection>
  );
}

export default MainSection;
