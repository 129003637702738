import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import {
  BrowserRouter,
  useNavigate,
} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import theme from './utils/brandTheme';
import { ClerkProvider } from '@clerk/clerk-react';

import ScrollToTop from './utils/scrollToTop';

const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={publishableKey}>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <ScrollToTop />
          <App />
        </ChakraProvider>
      </BrowserRouter>
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
