import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Heading,
  Badge,
  useColorModeValue,
  Icon,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { FiClock, FiWifi, FiMap } from 'react-icons/fi';
import { formatVolume } from '../../utils/Plans/plans';
import CountryFlag from './CountryFlag';
import countryUtils from '../../utils/Plans/country';
import OrderDrawer from './OrderDrawer';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';

// Add this helper function for network types
const getNetworkTypes = (operatorList) => {
  const types = new Set(operatorList.map(op => op.networkType));
  return Array.from(types).sort().join('/');
};

// Update the formatIpBreakout helper function
const formatIpBreakout = (ipExport, planLocation) => {
  if (!ipExport) return 'Local IP (No Breakout)';

  // If ipExport matches any of the plan locations, it's a local IP
  const planCountries = planLocation?.split(',') || [];
  if (planCountries.includes(ipExport)) {
    return 'Local IP (No Breakout)';
  }

  const countries = ipExport.split('/').map(code => {
    const countryName = countryUtils.getName(code);
    return countryName || code;
  });

  if (countries.length === 1) {
    return `IP Breakout: ${countries[0]}`;
  }

  return `IP Breakout: ${countries.join(' & ')}`;
};

const PlanCard = ({ plan }) => {
  const {
    isOpen: isDetailsOpen,
    onOpen: onDetailsOpen,
    onClose: onDetailsClose
  } = useDisclosure();

  const {
    isOpen: isCoverageOpen,
    onOpen: onCoverageOpen,
    onClose: onCoverageClose
  } = useDisclosure();

  const {
    isOpen: isOrderOpen,
    onOpen: onOrderOpen,
    onClose: onOrderClose
  } = useDisclosure();

  const [buying, setBuying] = useState(false);

  const { user, isLoaded } = useUser();
  const navigate = useNavigate();

  // Background and text color for higher contrast
  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  // Price helper
  const formatPrice = (price) => {
    const currency = localStorage.getItem('currency') || 'USD';
    const rate = parseFloat(localStorage.getItem('conversionRate') || 1);
    const convertedPrice = price * rate;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency
    }).format(convertedPrice);
  };

  // Extract plan info
  const dataVolume = formatVolume(plan.volume);
  const priceDisplay = formatPrice(plan.price / 10000);
  const durationText = `${plan.duration} ${plan.durationUnit}`;

  const operatorList = plan.locationNetworkList[0]?.operatorList || [];
  const firstOperator = operatorList[0]?.operatorName || 'Unknown Operator(s)';
  const operatorCount = operatorList.length;

  const operatorLabel =
    operatorCount > 1
      ? `${firstOperator}, and ${operatorCount - 1} other${operatorCount - 1 > 1 ? 's' : ''}`
      : firstOperator;

  const getPlanTypeBadge = () => {
    if (plan.slug.endsWith('_Daily')) {
      return <Badge colorScheme="purple">Unlimited</Badge>;
    }
    if (plan.slug.endsWith('_End')) {
      return <Badge colorScheme="blue">Non-reloadable</Badge>;
    }
    return <Badge colorScheme="green">Reloadable</Badge>;
  };

  const getPlanTitle = () => {
    const isDaily = plan.slug.endsWith('_Daily');
    const volume = formatVolume(plan.volume);

    if (isDaily) {
      return (
        <Stack spacing={0}>
          <Text fontSize="2xl" fontWeight="bold" lineHeight="1.2">
            {volume}/day
          </Text>
          <Text fontSize="sm" color="gray.500">
            High-speed data, then unlimited at reduced speed
          </Text>
        </Stack>
      );
    }

    return (
      <Stack spacing={0}>
        <Text fontSize="2xl" fontWeight="bold" lineHeight="1.2">
          {volume} Data
        </Text>
        <Text fontSize="sm" color="gray.500">
          Valid for {plan.duration} {plan.duration > 1 ? 'days' : 'day'}
        </Text>
      </Stack>
    );
  };

  // Add this helper function to sort countries
  const getSortedCountries = (locationString) => {
    if (!locationString) return [];
    const countries = locationString.split(',');
    return countries.sort((a, b) => {
      const nameA = countryUtils.getName(a);
      const nameB = countryUtils.getName(b);
      return nameA.localeCompare(nameB);
    });
  };

  // Add this for sorted network list
  const getSortedNetworkList = () => {
    if (!plan.locationNetworkList) return [];
    return [...plan.locationNetworkList].sort((a, b) =>
      a.locationName.localeCompare(b.locationName)
    );
  };

  const handlePurchaseClick = () => {
    if (!isLoaded) return; // Wait for auth to load

    if (!user) {
      // Redirect to sign in if not authenticated
      navigate('/sign-in');
      return;
    }

    onOrderOpen();
  };

  return (
    <Box
      p={6}
      borderRadius="lg"
      border="1px"
      borderColor={borderColor}
      bg={cardBg}
      boxShadow="sm"
    >
      <Flex justify="space-between" align="start" mb={4}>
        {getPlanTitle()}
        {getPlanTypeBadge()}
      </Flex>

      <Stack spacing={4}>
        <Flex align="center" gap={2}>
          <Icon as={FiClock} />
          <Text>Activate within {plan.unusedValidTime} days</Text>
        </Flex>

        <Flex align="center" gap={2}>
          <Icon as={FiWifi} />
          <Text>5G/4G/LTE where available</Text>
        </Flex>

        <Button
          variant="ghost"
          size="sm"
          leftIcon={<FiMap />}
          onClick={onCoverageOpen}
          justifyContent="flex-start"
          px={2}
        >
          <Flex align="center" gap={2} flex={1}>
            <Text>Coverage:</Text>
            {getSortedCountries(plan.location).slice(0, 3).map(code => (
              <CountryFlag key={code} isoCode={code} size={24} />
            ))}
            {plan.location?.split(',').length > 3 && (
              <Badge variant="subtle" colorScheme="purple">
                +{plan.location.split(',').length - 3} more
              </Badge>
            )}
          </Flex>
        </Button>

        <Divider />

        <Flex justify="space-between" align="center" wrap="wrap" gap={2}>
          <Box>
            <Text fontSize="2xl" fontWeight="bold">
              {priceDisplay}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {plan.slug.endsWith('_Daily') ? 'per day' : 'one-time payment'}
            </Text>
          </Box>
          <Stack direction="row" spacing={2} flexShrink={0}>
            <Button
              variant="outline"
              colorScheme="purple"
              size="sm"
              borderRadius="full"
              onClick={onDetailsOpen}
            >
              Details
            </Button>
            <Button
              colorScheme="purple"
              size="sm"
              borderRadius="full"
              onClick={onOrderOpen}
            >
              Purchase
            </Button>
          </Stack>
        </Flex>
      </Stack>

      {/* Coverage Modal */}
      <Modal isOpen={isCoverageOpen} onClose={onCoverageClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Coverage Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={4}>
              {getSortedNetworkList().map((location) => (
                <Box key={location.locationName} p={3} borderWidth="1px" borderRadius="md">
                  <Flex align="center" gap={2} mb={2}>
                    <CountryFlag
                      isoCode={countryUtils.getCode(location.locationName)}
                      size={24}
                    />
                    <Text fontWeight="bold">{location.locationName}</Text>
                  </Flex>
                  <Stack pl={8} spacing={1}>
                    {location.operatorList.map((operator, idx) => (
                      <Text key={idx} fontSize="sm">
                        • {operator.operatorName} - {operator.networkType}
                      </Text>
                    ))}
                  </Stack>
                </Box>
              ))}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Updated Plan Details Drawer */}
      <Drawer isOpen={isDetailsOpen} onClose={onDetailsClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Stack spacing={1}>
              <Text>{formatVolume(plan.volume)} Data Plan</Text>
              <Text fontSize="sm" color="gray.500" fontWeight="normal">
                {plan.slug.endsWith('_Daily')
                  ? 'Daily High-Speed Data'
                  : plan.slug.endsWith('_End')
                    ? 'Non-Reloadable Plan'
                    : 'Reloadable Plan'
                }
              </Text>
            </Stack>
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing={6}>
              <Box>
                <Text fontWeight="semibold" mb={2}>Plan Details</Text>
                <Stack spacing={3}>
                  <Flex justify="space-between">
                    <Text color="gray.500">Data Allowance</Text>
                    <Text>{formatVolume(plan.volume)}</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text color="gray.500">Duration</Text>
                    <Text>{plan.duration} {plan.duration > 1 ? 'Days' : 'Day'}</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text color="gray.500">Must Activate Within</Text>
                    <Text>{plan.unusedValidTime} Days</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text color="gray.500">Price</Text>
                    <Text>{priceDisplay}</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text color="gray.500">IP Configuration</Text>
                    <Text>{formatIpBreakout(plan.ipExport, plan.location)}</Text>
                  </Flex>
                </Stack>
              </Box>

              <Divider />

              <Box>
                <Text fontWeight="semibold" mb={2}>Coverage Information</Text>
                <Stack spacing={4}>
                  {getSortedNetworkList().map((location) => (
                    <Box key={location.locationName}>
                      <Flex align="center" gap={2} mb={1}>
                        <CountryFlag
                          isoCode={countryUtils.getCode(location.locationName)}
                          size={20}
                        />
                        <Text fontWeight="medium">{location.locationName}</Text>
                      </Flex>
                      <Text fontSize="sm" color="gray.500" ml={7}>
                        {location.operatorList.length} Networks • {getNetworkTypes(location.operatorList)}
                      </Text>
                    </Box>
                  ))}
                </Stack>
              </Box>

              {plan.slug.endsWith('_Daily') && (
                <>
                  <Divider />
                  <Box>
                    <Text fontWeight="semibold" mb={2}>Daily Reset Policy</Text>
                    <Text fontSize="sm" color="gray.600">
                      Your high-speed data allowance resets every 24 hours. After using your daily
                      high-speed data, you'll still have unlimited data at reduced speeds.
                    </Text>
                  </Box>
                </>
              )}
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button
              colorScheme="purple"
              size="lg"
              borderRadius="full"
              width="full"
              onClick={onOrderOpen}
            >
              Purchase Plan
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* OrderDrawer */}
      <OrderDrawer
        isOpen={isOrderOpen}
        onClose={onOrderClose}
        plan={plan}
      />
    </Box>
  );
};

export default PlanCard;
