/* src/components/Home/InteractiveMap.js */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Center,
  Spinner,
  useColorModeValue,
  useBreakpointValue,
  Tooltip,
  Flex, Heading, Button
} from '@chakra-ui/react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import styled from 'styled-components';

import CountryMapperISO3To2 from '../../utils/CountryMapperISO3To2';

const MapContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

const geoUrl =
  'https://raw.githubusercontent.com/subyfly/topojson/refs/heads/master/world-countries.json';

function InteractiveMap() {
  const [isLoading, setIsLoading] = useState(true);
  const [geographiesData, setGeographiesData] = useState(null);
  const [error, setError] = useState(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const navigate = useNavigate();

  // Optional: You might have a list of "coveredCountries" if you want to
  // visually distinguish which countries are truly covered.
  const coveredCountries = [
    'US', 'CA', 'FR', 'DE', 'IT', 'ES', 'TR', 'RU', 'GB', 'JP',
    // ... and so on
  ];

  // Fetching the map data manually so we can show a loading spinner
  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const response = await fetch(geoUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setGeographiesData(data);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching map data:', err);
        setError(err.message);
        setIsLoading(false);
      }
    };
    fetchMapData();
  }, []);

  const handleCountryClick = (geo) => {
    // Convert from ISO3 -> ISO2
    const iso2 = CountryMapperISO3To2(geo.id) ?? 'XX';
    // Then navigate to /plans/ISO2
    navigate(`/plans/${iso2}`);
  };

  // Color scheme
  const defaultFill = useColorModeValue('#e7e7e7', '#4A5568'); // light/dark variation
  const hoverFill = '#521263';   // bright hover color
  const pressedFill = '#521263';

  if (isLoading) {
    return (
      <Center py={10}>
        <Spinner color="purple.500" size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center py={10}>
        <Text color="red.500">Error loading map data: {error}</Text>
      </Center>
    );
  }

  return (
    <Box bg="gray.50" py="5" mb={!isMobile ? 10 : 0}>
      <Flex direction="column" align="center" maxW="1200px" mx="auto">
        {!isMobile && (<>
          <Heading size="xl" p="5" mb="3" textAlign="center">Where Else Do We Cover?</Heading>
          <Text fontSize="lg" mb="10" ml={5} mr={5}>Hover over the interactive map and select a destination!</Text>
          <MapContainer>
            <ComposableMap
              projectionConfig={{
                rotate: [-10, 0, 0],
                scale: 147,
              }}
              width={800}
              height={400}
              style={{ width: '100%', height: 'auto' }}
            >
              {geographiesData && (
                <Geographies geography={geographiesData}>
                  {({ geographies }) =>
                    geographies.map((geo) => {
                      const iso3 = geo.id;
                      const iso2 = CountryMapperISO3To2(iso3);
                      const countryName = geo.properties?.name || 'Unknown';

                      return (
                        <Tooltip
                          key={geo.rsmKey}
                          label={countryName}
                          placement="top"
                          hasArrow
                        >
                          <Geography
                            geography={geo}
                            onClick={() => handleCountryClick(geo)}
                            style={{
                              default: {
                                fill: defaultFill,
                                outline: 'none',
                                transition: 'fill 0.2s ease-in-out',
                              },
                              hover: {
                                fill: hoverFill,
                                outline: 'none',
                                cursor: 'pointer',
                              },
                              pressed: {
                                fill: pressedFill,
                                outline: 'none',
                              },
                            }}
                          />
                        </Tooltip>
                      );
                    })
                  }
                </Geographies>
              )}
            </ComposableMap>
          </MapContainer>
        </>
        )}
        <Button colorScheme="purple" size="lg" mt={!isMobile ? 10 : 0} borderRadius={'5rem'} onClick={() => {
          navigate('/plans');
        }}>Discover 185+ Destinations</Button>
      </Flex>
    </Box>

  );
}

export default InteractiveMap;
