// src/components/Home/FinalCTASection.js

import { useState } from 'react';
import {
  Box, Flex, Heading, Text, Button, Image, useColorModeValue, Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import BackgroundShape from '../General/BackgroundShape';

import { getMobileOperatingSystem } from '../../utils/device_detection';

const FinalCTASection = () => {
  const bg = useColorModeValue('purple.600', 'purple.900');
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalDetails, setModalDetails] = useState({
    title: '',
    description: <Text>Hello world</Text>,
    size: 'md',
    showClose: false
  });


  return (
    <BackgroundShape type="rectangle">
      <Modal isOpen={isOpen} onClose={onClose} size={modalDetails.size}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalDetails.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalDetails.description}
          </ModalBody>

          {modalDetails.showClose && <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>}

        </ModalContent>
      </Modal>
      <Box py="16" px="6">
        <Flex
          direction={{ base: 'column', md: 'row' }}
          align="center"
          justify="center"
          maxW="1200px"
          mx="auto"
        >
          <Box flex="1" color="white" mb={{ base: '8', md: '0' }}>
            <Heading size="xl" mb="4">
              Unlock Your World with Nesa Mobile
            </Heading>
            <Text fontSize="lg" mb="6" maxW="500px">
              Say goodbye to roaming fees and hello to unlimited possibilities. Stay connected, anywhere.
            </Text>
            <Button
              color="white"
              size="lg"
              borderRadius={'full'}
              style={{ backgroundColor: '#1A202C' }}
              onClick={() => {
                const operatingSystem = getMobileOperatingSystem();
                if (operatingSystem === 'ios') {
                  window.open("https://apps.apple.com/app/nesa-affordable-global-data/id6504453237");
                } else if (operatingSystem === 'android') {
                  window.open("https://play.google.com/store/apps/details?id=com.nesamobile.app");
                } else {
                  setModalDetails({
                    title: 'Download the Nesa Mobile App',
                    description:
                      <Box>
                        <p>Scan the QR Code on your mobile device to download the app!</p>
                        <Center>
                          <img src="/assets/images/nesa-mobile-app-download.png" alt="Nesa Mobile QR Code" />
                        </Center>
                      </Box>
                    ,
                    size: 'sm'
                  });
                  onOpen();
                }
              }}
            >
              Download App
            </Button>
          </Box>
        </Flex>
      </Box>
    </BackgroundShape>
  );
};

export default FinalCTASection;
