// src/components/Home/TestimonialSection.js

import React from 'react';
import { Box, Flex, Heading, Text, Avatar, SimpleGrid, useColorModeValue } from '@chakra-ui/react';

const TestimonialCard = ({ name, country, text, avatarUrl }) => {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.800')}
      p="6"
      borderRadius="md"
      boxShadow="md"
    >
      <Flex align="center" mb="4">
        <Avatar src={avatarUrl} name={name} mr="4" />
        <Box>
          <Text fontWeight="bold">{name}</Text>
          <Text fontSize="sm" color="gray.500">{country}</Text>
        </Box>
      </Flex>
      <Text fontStyle="italic">“{text}”</Text>
    </Box>
  );
};

const TestimonialSection = () => {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.700')}
      py="20"
      px="5"
    >
      <Flex direction="column" align="center" maxW="1000px" mx="auto">
        <Heading size="xl" mb="8">What Our Travelers Say</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="100%">
          <TestimonialCard
            name="Thabani Z."
            country="United States"
            text="I saved over $200 on my trip to Europe. Super easy setup and great coverage!"
            avatarUrl="/assets/images/avatars/user1.jpg"
          />
          <TestimonialCard
            name="Roxanne V."
            country="South Korea"
            text="No more local SIM card hassles. Nesa Mobile is a game-changer for frequent flyers."
            avatarUrl="/assets/images/avatars/user2.jpg"
          />
          <TestimonialCard
            name="Mirembe J."
            country="France"
            text="I loved the real-time data tracker. It gave me peace of mind during my vacation."
            avatarUrl="/assets/images/avatars/user3.jpg"
          />
        </SimpleGrid>
      </Flex>
    </Box>
  );
};

export default TestimonialSection;
