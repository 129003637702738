import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Heading,
  Text,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Grid,
  Badge,
  Flex,
  Divider,
  useColorModeValue,
  Button,
  Icon,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Select,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { FiDownload, FiClock, FiCheckCircle, FiXCircle, FiMap, FiInfo, FiGift } from 'react-icons/fi';
import { Link as ReactRouterLink } from 'react-router-dom';
import ApiClient from '../utils/API/api';
import { formatVolume, parsedSlug } from '../utils/Plans/plans';
import countryUtils from '../utils/Plans/country';
import CountryFlag from '../components/Plans/CountryFlag';

function Dashboard() {
  const { user } = useUser();
  const { getToken } = useAuth();
  const [activePlans, setActivePlans] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [filterStatus, setFilterStatus] = useState('ACTIVE_READY');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPlanDetailsOpen,
    onOpen: onPlanDetailsOpen,
    onClose: onPlanDetailsClose
  } = useDisclosure();
  const [selectedEsim, setSelectedEsim] = useState(null);

  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  // Memoize the API client creation
  const getApiClient = useCallback(async () => {
    const token = await getToken();
    return new ApiClient(process.env.REACT_APP_API_ENDPOINT, getToken);
  }, [getToken]);

  // Memoize the fetchUserData function
  const fetchUserData = useCallback(async () => {
    if (user?.id) {
      if (!user) return;

      setIsLoading(true);
      try {
        const apiClient = await getApiClient();
        const [activePlansResponse, historyResponse] = await Promise.all([
          apiClient.get('/esims'),
          apiClient.get('/orders')
        ]);

        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

        const recentPlans = activePlansResponse.data.esims
          .filter(plan => new Date(plan.createdAt) > sixMonthsAgo)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setActivePlans(recentPlans);
        setPurchaseHistory(historyResponse.data.transactions);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [user?.id]);

  // Update the data fetching useEffect
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // Memoize filtered plans
  const filteredPlansData = useMemo(() => {
    let filtered = [...activePlans];

    if (filterStatus === 'ACTIVE_READY') {
      filtered = filtered.filter(plan =>
        plan.eSimStatus?.toUpperCase() === 'ACTIVE' ||
        plan.eSimStatus?.toUpperCase() === 'GOT_RESOURCE'
      );
    } else if (filterStatus !== 'all') {
      filtered = filtered.filter(plan => plan.eSimStatus?.toUpperCase() === filterStatus);
    }

    return filtered;
  }, [activePlans, filterStatus]);

  // Update the filtering useEffect
  useEffect(() => {
    setFilteredPlans(filteredPlansData);
  }, [filteredPlansData]);

  // Memoize helper functions
  const formatDate = useCallback((dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }, []);

  const getStatusBadge = useCallback((status) => {
    switch (status?.toUpperCase()) {
      case 'ACTIVE':
        return <Badge colorScheme="green">Active</Badge>;
      case 'CANCEL':
        return <Badge colorScheme="red">Cancelled</Badge>;
      case 'EXPIRED':
        return <Badge colorScheme="orange">Expired</Badge>;
      case 'GOT_RESOURCE':
        return <Badge colorScheme="blue">Ready to Use</Badge>;
      default:
        return <Badge>{status}</Badge>;
    }
  }, []);

  const handleCountryClick = useCallback((plan) => {
    const countries = plan.plan?.location?.split(',') || [];
    const countryData = countries.map(code => ({
      code,
      name: countryUtils.getName(code),
      operators: plan.plan?.locationNetworkList
        ?.find(loc => loc.locationName === countryUtils.getName(code))
        ?.operatorList || []
    }));
    setSelectedCountries(countryData);
    onOpen();
  }, [onOpen]);

  // Move these component definitions up, before they're used
  const CountriesModal = () => (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Coverage Areas</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={4}>
            {selectedCountries.map(country => (
              <Box key={country.code} p={3} borderWidth="1px" borderRadius="md">
                <Flex align="center" gap={2} mb={2}>
                  <CountryFlag isoCode={country.code} size={24} />
                  <Text fontWeight="bold">{country.name}</Text>
                </Flex>
                <Box pl={8}>
                  <Text fontWeight="medium" mb={1}>Network Operators:</Text>
                  {country.operators?.map((operator, idx) => (
                    <Text key={idx} fontSize="sm">
                      • {operator.operatorName} - {operator.networkType}
                    </Text>
                  ))}
                </Box>
              </Box>
            ))}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  const PlanDetailsModal = ({ isOpen, onClose, esim, planData }) => (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>eSIM Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={4}>
            <Box>
              <Text fontWeight="bold" fontSize="md">Type of eSIM</Text>
              <Text>
                {esim?.eSimSlug?.endsWith('_End')
                  ? 'Non-reloadable'
                  : esim?.eSimSlug?.endsWith('_Daily')
                    ? 'Unlimited'
                    : 'Reloadable'}
              </Text>
            </Box>

            <Box>
              <Text fontWeight="bold" fontSize="md">Security Settings</Text>
              <Text>APN: {esim?.rawData?.body?.apn || 'Default'}</Text>
              <Text>SIM PIN: {esim?.rawData?.body?.pin || 'Not set'}</Text>
            </Box>

            <Box>
              <Text fontWeight="bold" fontSize="md">ICCID</Text>
              <Text>{esim.iccid || 'Not available yet'}</Text>
            </Box>

            <Box>
              <Text fontWeight="bold" fontSize="md">Device EID</Text>
              <Text>{esim?.rawData?.body?.eid || 'Not installed on device yet'}</Text>
            </Box>

            <Box>
              <Text fontWeight="bold" fontSize="md">Total Data</Text>
              <Text>{formatVolume(esim.plan?.volume || 0)}</Text>
            </Box>

            <Box>
              <Text fontWeight="bold" fontSize="md">Duration</Text>
              <Text>{esim.plan?.duration} {esim.plan?.duration === 1 ? 'Day' : 'Days'}</Text>
            </Box>

            {esim.eSimStatus === 'GOT_RESOURCE' && (
              <Box>
                <Text fontWeight="bold" fontSize="md">Activation By</Text>
                <Text>
                  You must activate this eSIM by {formatDate(esim.expiredTime)} or it will expire and will not be refundable.
                </Text>
              </Box>
            )}

            <Box>
              <Text fontWeight="bold" fontSize="md">Speed</Text>
              <Text>
                {getSpeedInfo(planData?.locationNetworkList) ||
                  'Network speed information is not available for this plan. Please contact customer service for more details.'}
              </Text>
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  // Then use them in memoization
  const CountriesModalMemo = useMemo(() => (
    <CountriesModal />
  ), [selectedCountries, isOpen]);

  const PlanDetailsModalMemo = useMemo(() => (
    selectedEsim && (
      <PlanDetailsModal
        isOpen={isPlanDetailsOpen}
        onClose={onPlanDetailsClose}
        esim={selectedEsim}
        planData={selectedEsim?.plan}
      />
    )
  ), [selectedEsim, isPlanDetailsOpen]);

  const getSpeedInfo = (locationNetworkList) => {
    if (!locationNetworkList?.length) return null;

    const networks = locationNetworkList.flatMap(location => location.operatorList || []);
    const hasG5 = networks.some(op => op.networkType?.includes('5G'));
    const hasG4 = networks.some(op => op.networkType?.includes('4G') || op.networkType?.includes('LTE'));
    const hasG3 = networks.some(op => op.networkType?.includes('3G'));

    if (!hasG5 && !hasG4 && !hasG3) return null;

    let speedText = 'This eSIM provides';
    const speeds = [];

    if (hasG5) speeds.push('5G');
    if (hasG4) speeds.push('4G/LTE');
    if (hasG3) speeds.push('3G');

    if (speeds.length === 1) {
      speedText += ` ${speeds[0]} speeds`;
    } else if (speeds.length === 2) {
      speedText += ` ${speeds[0]} and ${speeds[1]} speeds`;
    } else {
      speedText += ` ${speeds.slice(0, -1).join(', ')}, and ${speeds.slice(-1)} speeds`;
    }

    speedText += ' where available. Network performance may vary based on location, time of day, and network congestion.';

    return speedText;
  };

  const formatPlanName = (esim, getDetails = false) => {
    if (!esim.eSimSlug) return 'eSIM Plan';
    try {
      const parsed = parsedSlug(esim.eSimSlug);
      if (!getDetails) return parsed.country.name;

      // For daily plans
      if (esim.eSimSlug.endsWith('_Daily')) {
        return `${parsed.country.name} ${parsed.volume}/day`;
      }

      // For regular plans
      return `${parsed.country.name} ${parsed.volume} ${parsed.duration} Days`;
    } catch (error) {
      console.error('Error parsing slug:', error);
      return esim.plan?.name || 'eSIM Plan';
    }
  };

  if (isLoading) {
    return <Box p={8}>Loading...</Box>;
  }

  return (
    <Box maxW="1200px" mx="auto" p={8}>
      <Heading mb={6}>Welcome, {user.firstName}!</Heading>

      <Tabs colorScheme="purple" mb={8}>
        <TabList>
          <Tab>My eSIMs</Tab>
          <Tab>Purchase History</Tab>
          {/* <Tab>
            <Flex align="center" gap={2}>
              <Icon as={FiGift} />
              <Text>Coupons</Text>
            </Flex>
          </Tab> */}
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex justify="flex-end" mb={4}>
              <Select
                width="200px"
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <option value="ACTIVE_READY">Active & Ready to Use</option>
                <option value="all">All Status</option>
                <option value="ACTIVE">Active</option>
                <option value="GOT_RESOURCE">Ready to Use</option>
                <option value="EXPIRED">Expired</option>
                <option value="CANCEL">Cancelled</option>
              </Select>
            </Flex>

            <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
              {filteredPlans.map((esim) => (
                <Box
                  key={esim._id}
                  p={6}
                  borderRadius="lg"
                  border="1px"
                  borderColor={borderColor}
                  bg={cardBg}
                  boxShadow="sm"
                >
                  <Flex justify="space-between" align="center" mb={4}>
                    <Heading size="md">{formatPlanName(esim)}</Heading>
                    {getStatusBadge(esim.eSimStatus)}
                  </Flex>

                  <Stack spacing={3}>
                    <Text>
                      <Icon as={FiClock} mr={2} />
                      Expires: {formatDate(esim.expiredTime)}
                    </Text>
                    <Text>
                      Data: {formatVolume(esim.plan?.volume || 0)}
                    </Text>
                    <Text>
                      Duration: {esim.plan?.duration} {esim.plan?.durationUnit?.toLowerCase()}s
                    </Text>

                    <Flex align="center" gap={2}>
                      <Text>Coverage:</Text>
                      {esim.plan?.location?.split(',').slice(0, 3).map(code => (
                        <CountryFlag key={code} isoCode={code} size={24} />
                      ))}
                      {esim.plan?.location?.split(',').length > 3 && (
                        <Button
                          size="sm"
                          leftIcon={<FiMap />}
                          onClick={() => handleCountryClick(esim)}
                          variant="ghost"
                        >
                          +{esim.plan.location.split(',').length - 3} more
                        </Button>
                      )}
                    </Flex>

                    <Divider />

                    <Flex gap={2}>
                      {esim.ac && (
                        <Tooltip label="Download QR Code">
                          <Button
                            leftIcon={<FiDownload />}
                            size="sm"
                            onClick={() => window.open(esim.rawData?.body?.qrCodeUrl, '_blank')}
                          >
                            QR Code
                          </Button>
                        </Tooltip>
                      )}
                      <Tooltip label="View Details">
                        <Button
                          leftIcon={<FiInfo />}
                          size="sm"
                          variant="ghost"
                          onClick={() => {
                            setSelectedEsim(esim);
                            onPlanDetailsOpen();
                          }}
                        >
                          Details
                        </Button>
                      </Tooltip>
                    </Flex>
                  </Stack>
                </Box>
              ))}
            </Grid>
          </TabPanel>

          <TabPanel>
            <Stack spacing={4}>
              {purchaseHistory.map((purchase) => (
                <Box
                  key={purchase._id}
                  p={6}
                  borderRadius="lg"
                  border="1px"
                  borderColor={borderColor}
                  bg={cardBg}
                >
                  <Stack spacing={4}>
                    {purchase.esims.map((esim, index) => (
                      <Box key={index}>
                        <Flex justify="space-between" align="center" mb={2}>
                          <Heading size="sm">{formatPlanName(esim, true)}</Heading>
                          <Badge
                            colorScheme={purchase.isCompleted ? "green" : "orange"}
                          >
                            {purchase.isCompleted ? "Completed" : "Pending"}
                          </Badge>
                        </Flex>

                        <Stack spacing={2} mt={2}>
                          <Text fontSize="sm" color="gray.500">
                            Order ID: {purchase._id}
                          </Text>
                          <Text fontSize="sm">
                            Purchased: {formatDate(purchase.createdAt)}
                          </Text>
                          <Text fontSize="sm">
                            {esim.plan?.volume && `Data: ${formatVolume(esim.plan.volume)}`}
                            {esim.plan?.duration && ` • ${esim.plan.duration} ${esim.plan.durationUnit?.toLowerCase()}s`}
                          </Text>
                          {esim.eSimStatus && (
                            <Text fontSize="sm">
                              Status: {getStatusBadge(esim.eSimStatus)}
                            </Text>
                          )}
                        </Stack>

                        <Divider mt={2} />

                        <Flex justify="space-between" mt={2}>
                          <Text fontSize="sm">
                            Amount: {purchase.amount} {purchase.currency.toUpperCase()}
                          </Text>
                          {purchase.pointsEarned && (
                            <Text fontSize="sm">
                              Points: {Math.abs(purchase.pointsEarned)} {purchase.pointsEarned > 0 ? "earned" : "redeemed"}
                            </Text>
                          )}
                          <Text fontSize="sm">
                            {purchase.stripePaymentId === 'free' ? 'Points Redemption' : 'Card Payment'}
                          </Text>
                        </Flex>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              ))}
            </Stack>
          </TabPanel>
          {/* 
          <TabPanel>
            <Box textAlign="center" py={8}>
              <Icon as={FiGift} boxSize={12} color="purple.500" mb={4} />
              <Heading size="lg" mb={4}>Coupon Statistics</Heading>
              <Text mb={6}>Track your savings and view detailed statistics about your coupon usage.</Text>
              <Button
                as={ReactRouterLink}
                to="/partner-dashboard"
                colorScheme="purple"
                size="lg"
                leftIcon={<FiGift />}
              >
                View Partner Dashboard
              </Button>
            </Box>
          </TabPanel> */}
        </TabPanels>
      </Tabs>

      {selectedEsim && (
        <>
          {CountriesModalMemo}
          {PlanDetailsModalMemo}
        </>
      )}
    </Box>
  );
}

// Memoize the entire Dashboard component
export default React.memo(Dashboard); 