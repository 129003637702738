import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Card,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  StatHelpText,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spinner,
  Center,
  useToast,
  SimpleGrid,
  Icon,
  Tooltip,
  Progress,
  Flex,
  Button
} from '@chakra-ui/react';
import { useUser, useAuth } from '@clerk/clerk-react';
import ApiClient from '../utils/API/api';
import { FiUsers, FiTrendingUp, FiClock, FiGift, FiList } from 'react-icons/fi';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

const CouponStats = () => {
  const { user } = useUser();
  const { getToken } = useAuth();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCouponStats = async () => {
      if (!user) return;

      try {
        const token = await getToken();
        const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => token);
        const result = await apiClient.get('partner/coupon-stats', {}, false);
        setStats(result.data);
      } catch (error) {
        console.error('Error fetching coupon stats:', error);
        toast({
          title: 'Error',
          description: error.response?.data?.message || 'Failed to load coupon statistics',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    if (user?.id) {
      fetchCouponStats();
    }
  }, [user?.id]);

  const formatCurrency = (amount, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(priceFormatter(amount));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const getPeriodMetric = (period) => {
    return stats?.overallStats?.periodMetrics?.[period] || {
      redemptions: 0,
      revenue: 0
    };
  };

  const priceFormatter = (price) => {
    return price / 100;
  }

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" color="purple.500" />
      </Center>
    );
  }

  if (!stats) {
    return (
      <Center h="100vh">
        <Text>No coupon statistics available</Text>
      </Center>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <Stack spacing={8}>
        {/* Header with Navigation */}
        <Flex justify="space-between" align="center">
          <Heading size="lg">Partner Dashboard</Heading>
          <Button
            leftIcon={<FiList />}
            colorScheme="purple"
            variant="outline"
            as={RouterLink}
            to="/partner-dashboard/transactions"
          >
            View Transactions
          </Button>
        </Flex>

        {/* Overall Performance */}
        <Box>
          <Heading size="lg" mb={6}>Partner Performance Overview</Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
            <Card>
              <CardBody>
                <Stat>
                  <StatLabel>Total Coupons</StatLabel>
                  <StatNumber>{stats?.overallStats?.totalCouponsCreated || 0}</StatNumber>
                  <StatHelpText>
                    <Badge colorScheme="green">{stats?.overallStats?.activeCoupons || 0} Active</Badge>
                  </StatHelpText>
                </Stat>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Stat>
                  <StatLabel>Total Redemptions</StatLabel>
                  <StatNumber>{stats?.overallStats?.totalRedemptions || 0}</StatNumber>
                  <StatHelpText>
                    <Icon as={FiUsers} mr={1} />
                    {stats?.overallStats?.uniqueCustomers || 0} Unique Customers
                  </StatHelpText>
                </Stat>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Stat>
                  <StatLabel>Total Partner Commission</StatLabel>
                  <StatNumber>{formatCurrency(parseFloat(stats?.overallStats?.totalCommission) * 100 || 0)}</StatNumber>
                  <StatHelpText>
                    <Icon as={FiTrendingUp} mr={1} />
                    Lifetime Value
                  </StatHelpText>
                </Stat>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Stat>
                  <StatLabel>Recent Activity</StatLabel>
                  <StatNumber>{getPeriodMetric('last24h').redemptions}</StatNumber>
                  <StatHelpText>
                    <Icon as={FiClock} mr={1} />
                    Redemptions in 24h
                  </StatHelpText>
                </Stat>
              </CardBody>
            </Card>
          </SimpleGrid>
        </Box>

        {/* Period Metrics */}
        <Box>
          <Heading size="md" mb={4}>Period Performance</Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
            <Card>
              <CardBody>
                <Text fontWeight="medium" mb={2}>Last 24 Hours</Text>
                <Stack spacing={2}>
                  <Text>Redemptions: {getPeriodMetric('last24h').redemptions}</Text>
                  <Text>Revenue: {formatCurrency(getPeriodMetric('last24h').revenue)}</Text>
                </Stack>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Text fontWeight="medium" mb={2}>Last 7 Days</Text>
                <Stack spacing={2}>
                  <Text>Redemptions: {getPeriodMetric('last7d').redemptions}</Text>
                  <Text>Revenue: {formatCurrency(getPeriodMetric('last7d').revenue)}</Text>
                </Stack>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Text fontWeight="medium" mb={2}>Last 30 Days</Text>
                <Stack spacing={2}>
                  <Text>Redemptions: {getPeriodMetric('last30d').redemptions}</Text>
                  <Text>Revenue: {formatCurrency(getPeriodMetric('last30d').revenue)}</Text>
                </Stack>
              </CardBody>
            </Card>
          </SimpleGrid>
        </Box>

        {/* Detailed Coupon Statistics */}
        <Box>
          <Heading size="lg" mb={6}>Coupon Performance</Heading>
          <Accordion allowMultiple>
            {Object.entries(stats.couponStats).map(([couponCode, couponData]) => (
              <AccordionItem key={couponCode}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text fontWeight="bold">{couponCode}</Text>
                  </Box>
                  <Badge colorScheme={couponData.couponDetails.type === 'percentage' ? 'purple' : 'blue'} mx={2}>
                    {couponData.couponDetails.type === 'percentage'
                      ? `${couponData.couponDetails.value}%`
                      : formatCurrency(priceFormatter(couponData.couponDetails.value))}
                  </Badge>
                  <Badge colorScheme="green" mx={2}>
                    Active
                  </Badge>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <Stack spacing={6}>
                    {/* Coupon Details */}
                    <Box>
                      <Text fontWeight="medium" mb={2}>Coupon Details</Text>
                      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                        <Stack spacing={2}>
                          <Text>Created: {formatDate(couponData.couponDetails.createdAt)}</Text>
                          <Text>Valid Until: {couponData.couponDetails.redeemBy ? formatDate(couponData.couponDetails.redeemBy) : 'Unlimited'}</Text>
                          <Text>Max Redemptions: {couponData.couponDetails.maxRedemptions}</Text>
                        </Stack>
                        <Stack spacing={2}>
                          <Text>Verification Required: {couponData.couponDetails.requireVerification ? 'Yes' : 'No'}</Text>
                          <Text>Usage: {couponData.performance.totalRedemptions} redemptions</Text>
                          <Text>Unique Users: {couponData.performance.uniqueUsers}</Text>
                        </Stack>
                      </SimpleGrid>
                    </Box>

                    {/* Performance Metrics */}
                    <Box>
                      <Text fontWeight="medium" mb={4}>Performance Metrics</Text>
                      <StatGroup>
                        <Stat>
                          <StatLabel>Total Revenue</StatLabel>
                          <StatNumber>{formatCurrency(couponData.performance.totalRevenue)}</StatNumber>
                        </Stat>
                        <Stat>
                          <StatLabel>Average Order Value</StatLabel>
                          <StatNumber>{formatCurrency(couponData.performance.averageOrderValue)}</StatNumber>
                        </Stat>
                      </StatGroup>
                    </Box>

                    {/* Recent Transactions */}
                    <Box>
                      <Text fontWeight="medium" mb={4}>Recent Transactions</Text>
                      <Table variant="simple" size="sm">
                        <Thead>
                          <Tr>
                            <Th>Date</Th>
                            <Th>User</Th>
                            <Th>Product</Th>
                            <Th isNumeric>Amount</Th>
                            <Th isNumeric>Commission</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {couponData.recentTransactions.map((transaction, index) => (
                            <Tr key={index}>
                              <Td>{formatDate(transaction.date)}</Td>
                              <Td>{transaction.userEmail}</Td>
                              <Td>
                                {transaction.product?.planName
                                  ? `${transaction.product.planName}${transaction.product.totalDays ? ` (${transaction.product.totalDays} days)` : ''}`
                                  : transaction.product?.topUpDetails || 'N/A'}
                              </Td>
                              <Td isNumeric>{formatCurrency(transaction.amount, transaction.currency)}</Td>
                              <Td isNumeric>{formatCurrency(parseFloat(transaction.commission) * 100, transaction.currency)}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Stack>
    </Container>
  );
};

export default CouponStats; 