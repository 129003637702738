import countryList from "./country";
const regionalData = require("./regional.json");

function filterAndSortPackages(data, locationCode) {
    // Filter the packages that contain the specific location code
    const filteredPackages = data.filter((planPackage) =>
        planPackage.location.split(',').includes(locationCode)
    );

    // Sort the packages:
    // 1. By the number of locations (ascending)
    // 2. By volume (ascending)
    filteredPackages.sort((a, b) => {
        const locationCountA = a.location.split(',').length;
        const locationCountB = b.location.split(',').length;

        if (locationCountA !== locationCountB) {
            return locationCountA - locationCountB;
        } else {
            return a.volume - b.volume;
        }
    });

    return filteredPackages;
}

const locationsWithOwnPlan = (plans) => {
    const locations = [];
    plans.forEach(plan => {
        const location = plan.location.split(',');
        if (location.length === 1) {
            locations.push(location[0]);
        }
    });
    return Array.from(new Set(locations));
};

function filterAndSortPackagesOnlyOneCC(data, locationCode) {
    // Filter the packages that contain the specific location code
    const filteredPackages = data.filter((planPackage) =>
        planPackage.location === locationCode
    );

    // Sort the packages:
    // 1. By the number of locations (ascending)
    // 2. By volume (ascending)
    filteredPackages.sort((a, b) => {
        const locationCountA = a.location && typeof a.location === 'string' ? a.location.split(',').length : 0;
        const locationCountB = b.location && typeof b.location === 'string' ? b.location.split(',').length : 0;

        if (locationCountA !== locationCountB) {
            return locationCountA - locationCountB;
        } else {
            // Ensure volume is a number and handle possible undefined/null values
            const volumeA = typeof a.volume === 'number' ? a.volume : 0;
            const volumeB = typeof b.volume === 'number' ? b.volume : 0;
            return volumeA - volumeB;
        }
    });

    return filteredPackages;
}

function filterAndSortForRegion(data, region) {
    // Filter the packages that have a slug that starts with `region` string:
    const filteredPackages = data.filter((planPackage) =>
        planPackage.slug.startsWith(`${region}-`)
    );

    // Sort the packages:
    // 1. By the number of locations (ascending)
    // 2. By volume (ascending)
    filteredPackages.sort((a, b) => {
        const locationCountA = a.location && typeof a.location === 'string' ? a.location.split(',').length : 0;
        const locationCountB = b.location && typeof b.location === 'string' ? b.location.split(',').length : 0;

        if (locationCountA !== locationCountB) {
            return locationCountA - locationCountB;
        } else {
            // Ensure volume is a number and handle possible undefined/null values
            const volumeA = typeof a.volume === 'number' ? a.volume : 0;
            const volumeB = typeof b.volume === 'number' ? b.volume : 0;
            return volumeA - volumeB;
        }
    });
    return filteredPackages;
}


const formatVolume = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
    const size = bytes / Math.pow(1024, i);
    const roundedSize = Math.round(size * 2) / 2; // Round to nearest 0.5
    const output =
        roundedSize % 1 === 0 ? roundedSize.toFixed(0) : roundedSize.toFixed(1);
    return output + " " + sizes[i];
};

const parsedSlug = (slug) => {
    // String format is 'COUNTRY_VOLUME_DURATION'
    const split = slug.split("_");
    const volume = parseFloat(split[1]) < 1 ? `${parseFloat(split[1]) * 1000} MB` : `${split[1]} GB`;
    // If first part is a country code, return the country name
    // if it is separated by - it is 'REGION-NUMBEROFCOUNTRIES'
    if (split[0].length === 2) {
        const countryName = countryList.getName(split[0]);
        return {
            isRegion: false,
            country: {
                name: countryName,
                code: split[0],
                totalCountries: 1,
            },
            volume: volume,
            duration: split[2] === 'Daily' ? 1 : split[2],
        };
    } else {
        const temp = regionalData.filter(
            (region) => region.code === split[0].split("-")[0]
        )[0];
        return {
            isRegion: true,
            country: {
                name: temp.name,
                code: temp.code,
                totalCountries: parseInt(split[0].split("-")[1]),
            },
            volume: volume,
            duration: split[2] === 'Daily' ? 1 : split[2],
        };
    }
};

export { filterAndSortPackages, filterAndSortPackagesOnlyOneCC, filterAndSortForRegion, locationsWithOwnPlan, formatVolume, parsedSlug };