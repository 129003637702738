import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  Button,
  List,
  ListItem,
  useBreakpointValue,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  SimpleGrid,
  IconButton,
  Divider,
  Icon
} from '@chakra-ui/react';
import { FaFilter } from 'react-icons/fa';
import DestinationCard from '../components/Plans/DestinationCard';
import SearchBar from '../components/Plans/SearchBar';
import { trackEvent } from '../utils/analytics';

import ApiClient from '../utils/API/api';
import countryUtils from '../utils/Plans/country';
import { locationsWithOwnPlan } from '../utils/Plans/plans';
import { useNavigate } from 'react-router-dom';

import { PiGlobe } from "react-icons/pi";


const PlansScreen = () => {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [standaloneLocations, setStandaloneLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Region filters
  const [regionSelector, setRegionSelector] = useState([
    { name: 'All', code: 'ALL', selected: true },
    { name: 'Europe', code: 'EU', selected: false, isRegion: true },
    { name: 'North America', code: 'NA', selected: false, isRegion: true },
    { name: 'South America', code: 'SA', selected: false, isRegion: true },
    { name: 'Asia', code: 'AS', selected: false, isRegion: true },
    { name: 'Caribbean', code: 'CB', selected: false, isRegion: true },
    { name: 'Middle East', code: 'ME', selected: false, isRegion: true },
    { name: 'Africa', code: 'AF', selected: false, isRegion: true },
    // you can add 'Oceania' or 'Global' if you have coverage
  ]);

  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: false });
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => '');
      try {
        const response = await apiClient.get('/plans');
        setData(response.data);

        // Collect all locations that have their own plan
        const standalone = locationsWithOwnPlan(response.data.obj.packageList);
        setStandaloneLocations(standalone);

        // Default: show all destinations
        setDestinations(countryUtils.searchCountry('', standalone) ?? []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // This triggers filtering by region
  const applyRegionFilter = () => {
    const selectedRegion = regionSelector.find((r) => r.selected);

    if (!selectedRegion || selectedRegion.code === 'ALL') {
      // Show everything if "All" is selected
      setDestinations(countryUtils.searchCountry('', standaloneLocations) ?? []);
      return;
    }

    // Track region filter with GA4 parameters
    trackEvent('select_content', {
      content_type: 'region_filter',
      content_id: selectedRegion.code,
      content: selectedRegion.name
    });

    // Filter by region name in the data
    setDestinations(
      countryUtils
        .getData()
        .filter(
          (country) =>
            country.region === selectedRegion.name &&
            standaloneLocations.includes(country.code)
        )
    );
  };

  // Keep region filter consistent whenever regionSelector changes
  useEffect(() => {
    applyRegionFilter();
  }, [regionSelector]);

  // Keep search results updated
  useEffect(() => {
    if (searchQuery === '') {
      // If no search, fallback to region filter
      applyRegionFilter();
    } else {
      // Filter by search query across all standalone countries
      setDestinations(countryUtils.searchCountry(searchQuery, standaloneLocations) ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  // Toggles the selected region in state
  const toggleRegionSelection = (clickedRegion) => {
    setRegionSelector((current) =>
      current.map((reg) => {
        // If user clicks "All," deselect all others
        if (clickedRegion.name === 'All' && clickedRegion.name === reg.name) {
          return { ...reg, selected: true };
        }
        // If user selects a region while "All" was selected, switch
        if (clickedRegion.name !== 'All' && reg.name === 'All') {
          return { ...reg, selected: false };
        }
        // Toggle the clicked region
        if (reg.name === clickedRegion.name) {
          return { ...reg, selected: !reg.selected };
        }
        // Deselect others
        return { ...reg, selected: false };
      })
    );
  };

  return (
    <Flex direction="column" p="5">
      {/* Top Bar: Search + Filter icon on mobile */}
      <Flex justifyContent={isMobile ? 'space-around' : 'center'} alignItems="center" mb="5">
        <SearchBar
          placeholder="Find your destination..."
          showIcon={!isMobile}
          width={isMobile ? '85%' : '65%'}
          setSearchQuery={setSearchQuery}
        />
        {isMobile && (
          <IconButton
            ml={2}
            icon={<FaFilter />}
            onClick={onOpen}
            aria-label="Filter"
          />
        )}
      </Flex>

      {/* Main Container with Regions (Sidebar or Drawer) + Destination Cards */}
      <Flex flex="1" flexDirection={isMobile ? 'column' : 'row'} minH="calc(100vh - 100px)">
        {/* SIDEBAR (Desktop) */}
        {!isMobile && (
          <Box width="20%" p="5" bg="gray.50" borderRadius="md" mr="5">
            <Heading size="md" mb="4">
              Explore by Region
            </Heading>
            <VStack align="start" spacing="3">
              {regionSelector.map((plan) => (
                <Button
                  key={plan.name}
                  variant={plan.selected ? 'solid' : 'ghost'}
                  colorScheme={plan.selected ? 'purple' : 'gray'}
                  borderRadius="full"
                  onClick={() => toggleRegionSelection(plan)}
                  width="100%"
                  justifyContent="flex-start"
                >
                  {plan.name}
                </Button>
              ))}
            </VStack>
          </Box>
        )}

        {/* DESTINATIONS */}
        <Box flex="1" p="5" overflowY="auto">
          <Heading size="lg" mb="4">
            {searchQuery
              ? `Results for "${searchQuery}"`
              : 'Find the Perfect eSIM for Your Journey'}
          </Heading>
          <Text fontSize="md" mb="6" color="gray.600">
            Choose from thousands of destinations worldwide or browse by region.
            Enjoy fast, secure, and affordable data—no matter where you roam.
          </Text>

          <Divider mb="6" />

          {regionSelector.map((plan) => {
            if (plan.selected && plan.isRegion) {
              return (
                <>
                  <Button
                    variant={'solid'}
                    borderRadius={'full'}
                    style={{
                      backgroundColor: '#1A202C',
                    }}
                    onClick={() => {
                      navigate(`/regions/${plan.code}`);
                    }}
                  >
                    {/* image for link */}
                    <Icon as={PiGlobe} /> &nbsp;
                    See Regional Plans for {plan.name}
                  </Button>
                  <Divider mt="6" mb="6" />
                </>
              );
            }
          })}

          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
            spacing={5}
            justifyItems="center"
          >
            {destinations.map((destination) => {
              let landmark;
              if (destination?.landmarks?.length) {
                landmark =
                  destination.landmarks[
                  Math.floor(Math.random() * destination.landmarks.length)
                  ];
              } else {
                landmark = '';
              }
              return (
                <DestinationCard
                  key={destination.code}
                  code={destination.code}
                  country={destination.name}
                  price={destination.price}
                  landmarkUrl={
                    process.env.PUBLIC_URL +
                    '/assets/images/landmarks/' +
                    landmark +
                    '.png'
                  }
                />
              );
            })}
          </SimpleGrid>
        </Box>
      </Flex>

      {/* MOBILE DRAWER (Filtering by Region) */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Filter By Region</DrawerHeader>
            <DrawerBody>
              <VStack align="start" spacing="5">
                <List spacing={2} width="100%">
                  {regionSelector.map((plan) => (
                    <ListItem key={plan.name}>
                      <Button
                        variant={plan.selected ? 'solid' : 'ghost'}
                        colorScheme={plan.selected ? 'purple' : 'gray'}
                        borderRadius="full"
                        width="100%"
                        justifyContent="flex-start"
                        onClick={() => {
                          toggleRegionSelection(plan);
                        }}
                      >
                        {plan.name}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </VStack>
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
};

export default PlansScreen;
