import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Image,
  Flex,
  Button,
  Divider,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

function AboutUs() {
  return (
    <Container maxW="container.xl" py={10} minH="100vh">
      <VStack spacing={10} align="start">
        {/* About Us Section */}
        <HStack spacing={5} w="full" align="start" flexWrap="wrap">
          <Box flex="1" minW={{ base: '100%', md: '50%' }}>
            <Heading size="2xl">About Nesa</Heading>
            <Text fontSize="lg" mt={3}>
              Travel smarter. Stay connected. Nesa makes global connectivity simple, seamless,
              and affordable. With eSIMs designed for every adventure, you can say goodbye
              to roaming fees and hello to worry-free data.
            </Text>
            <Text fontSize="md" mt={3} color="gray.500">
              Whether you're hopping between countries or working from paradise, Nesa keeps
              you online wherever life takes you.
            </Text>
          </Box>

          <Image
            src="/assets/images/hero_image.png"
            alt="About Us"
            // borderRadius="md"
            display={{ base: 'none', md: 'block' }}
            flex="1"
            maxH="300px"
            maxW={'auto'}
            objectFit="contain"
          />
        </HStack>

        <Divider />

        {/* Why Choose Us Section */}
        <Box w="full">
          <Heading size="xl" textAlign="center">
            Why Choose Nesa?
          </Heading>
          <Text mt={4} textAlign="center">
            Because you deserve reliable, hassle-free connectivity—anywhere in the world.
          </Text>

          <Flex mt={8} wrap="wrap" justify="space-between" gap={6}>
            <VStack spacing={4} align="start" flexBasis={{ base: '100%', md: '30%' }}>
              <Box>
                <Heading size="md">No Roaming Fees</Heading>
                <Text>Stay online without breaking the bank. Our plans are designed to save you money.</Text>
              </Box>
            </VStack>

            <VStack spacing={4} align="start" flexBasis={{ base: '100%', md: '30%' }}>
              <Box>
                <Heading size="md">Instant Activation</Heading>
                <Text>Get connected within minutes—no physical SIM cards or extra steps.</Text>
              </Box>
            </VStack>

            <VStack spacing={4} align="start" flexBasis={{ base: '100%', md: '30%' }}>
              <Box>
                <Heading size="md">185+ Destinations</Heading>
                <Text>From bustling cities to remote escapes, we’ve got you covered.</Text>
              </Box>
            </VStack>
          </Flex>
        </Box>

        <Divider />

        {/* Our Promise */}
        <Box w="full">
          <Heading size="xl" mb={4}>
            Our Promise
          </Heading>
          <Text fontSize="lg">
            To make every journey smoother, every connection stronger, and every adventure
            unforgettable.
          </Text>
        </Box>

        {/* Call-to-Action */}
        <VStack spacing={5} py={10} w="full" justify="center" textAlign="center">
          <Box flex="1">
            <Heading size="xl">Join Us</Heading>
            <Text fontSize="lg" mt={3}>
              Be part of a world where staying connected is easy, affordable, and always reliable.
            </Text>
            <Button
              variant="solid"
              as={ReactRouterLink}
              colorScheme="purple"
              mt={5}
              to="/contact"
            >
              Get Started
            </Button>
          </Box>
        </VStack>
      </VStack>
    </Container>
  );
}

export default AboutUs;
