import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Heading,
  Text,
  Image,
  Spinner,
  useColorModeValue,
  Link as ChakraLink,
  ListItem,
  UnorderedList,
  OrderedList,
  Code,
  Grid,
  Avatar,
  VStack,
  HStack,
  IconButton,
  Divider,
  Stack,
} from '@chakra-ui/react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { FaExternalLinkAlt, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';

// --- Helper Functions to Extract Headings ---
function getHeadingsFromHTML(htmlString) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  const headingNodes = tempDiv.querySelectorAll('h2, h3');
  const headings = [];

  headingNodes.forEach((node, index) => {
    // Generate a unique ID for the heading (can be based on text or index)
    const textContent = node.textContent || `Heading-${index}`;
    const slugId = textContent
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-|-$/g, '');

    headings.push({
      id: slugId,
      text: textContent,
      level: node.tagName.toLowerCase(), // "h2" or "h3"
    });
  });

  return headings;
}

export default function BlogPost() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [nextPosts, setNextPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  // State for storing headings
  const [headings, setHeadings] = useState([]);
  // Track which heading is active in the viewport
  const [activeHeadingId, setActiveHeadingId] = useState('');

  const textColor = useColorModeValue('gray.700', 'gray.200');
  const codeColor = useColorModeValue('gray.100', 'gray.700');
  const blockColor = useColorModeValue('gray.600', 'gray.400');
  const authorBlockColor = useColorModeValue('gray.100', 'gray.700');

  // WordPress API endpoints
  const WP_API_URL = `https://nesamobile.blog/wp-json/wp/v2/posts?slug=${slug}&_embed`;
  const NEXT_POSTS_API_URL = `https://nesamobile.blog/wp-json/wp/v2/posts?per_page=3&orderby=date&order=desc&_embed`;

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(WP_API_URL);
        const data = await response.json();
        if (data.length) {
          setPost(data[0]);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setLoading(false);
      }
    };

    const fetchNextPosts = async () => {
      try {
        const response = await fetch(NEXT_POSTS_API_URL);
        const data = await response.json();
        // Exclude the current post
        const filteredPosts = data.filter((p) => p.slug !== slug);
        setNextPosts(filteredPosts.slice(0, 3));
      } catch (error) {
        console.error('Error fetching next posts:', error);
      }
    };

    fetchPost();
    fetchNextPosts();
  }, [slug]);

  // Once post is fetched, extract headings from post.content.rendered
  useEffect(() => {
    if (post?.content?.rendered) {
      const extractedHeadings = getHeadingsFromHTML(post.content.rendered);
      setHeadings(extractedHeadings);
    }
  }, [post]);

  // Create refs for each heading in the rendered content to track intersection
  const headingRefs = useRef({});

  // Use IntersectionObserver to highlight active heading
  useEffect(() => {
    if (!headings || headings.length === 0) return;

    const observerOptions = {
      root: null,
      rootMargin: '0px 0px -60% 0px',
      threshold: 0,
    };

    const callback = (entries) => {
      // Find the heading that is currently intersecting
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveHeadingId(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(callback, observerOptions);

    headings.forEach((heading) => {
      const el = document.getElementById(heading.id);
      if (el) observer.observe(el);
    });

    return () => {
      observer.disconnect();
    };
  }, [headings]);

  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner color="purple.500" size="xl" />
      </Box>
    );
  }

  if (!post) {
    return (
      <Box textAlign="center" py={10}>
        <Heading size="lg">Post not found</Heading>
      </Box>
    );
  }

  // Featured image
  const featuredImage =
    post._embedded?.['wp:featuredmedia']?.[0]?.source_url || null;

  // Author details
  const author = post._embedded?.author?.[0];
  const authorAvatar =
    author?.avatar_urls?.['96'] || 'https://via.placeholder.com/150'; // Fallback avatar

  const publishDate = formatDistanceToNow(new Date(post.date), { addSuffix: true });

  // We override the parse for h2, h3 to inject an id
  const options = {
    replace: ({ name, attribs, children }) => {
      if (!name) return;

      // Generate a stable slug ID for the heading if it's in our headings array
      const findHeading = (text) => {
        return headings.find((h) => h.text === text);
      };

      switch (name) {
        case 'h1':
          return (
            <Heading as="h1" size="2xl" my={4}>
              {domToReact(children, options)}
            </Heading>
          );
        case 'h2': {
          // We need the text content of the heading to match
          const textContent = children
            .map((child) => (typeof child === 'string' ? child : ''))
            .join('');
          const matching = findHeading(textContent) || {};

          return (
            <Heading
              as="h2"
              size="xl"
              my={4}
              id={matching.id} // Attach the ID
              ref={(el) => {
                if (el && matching.id) {
                  headingRefs.current[matching.id] = el;
                }
              }}
            >
              {domToReact(children, options)}
            </Heading>
          );
        }
        case 'h3': {
          const textContent = children
            .map((child) => (typeof child === 'string' ? child : ''))
            .join('');
          const matching = findHeading(textContent) || {};

          return (
            <Heading
              as="h3"
              size="lg"
              my={4}
              id={matching.id}
              ref={(el) => {
                if (el && matching.id) {
                  headingRefs.current[matching.id] = el;
                }
              }}
            >
              {domToReact(children, options)}
            </Heading>
          );
        }
        case 'p':
          return (
            <Text fontSize="md" mb={4}>
              {domToReact(children, options)}
            </Text>
          );
        case 'a':
          return (
            <ChakraLink
              href={attribs.href}
              color="teal.500"
              isExternal={attribs.target === '_blank'}
              {...(attribs.target === '_blank' && { rel: 'noopener noreferrer' })}
            >
              {domToReact(children, options)}
              {attribs.target === '_blank' && (
                <FaExternalLinkAlt style={{ marginLeft: 2, marginRight: 2 }} />
              )}
            </ChakraLink>
          );
        case 'ul':
          return (
            <UnorderedList mb={4} pl={5}>
              {domToReact(children, options)}
            </UnorderedList>
          );
        case 'ol':
          return (
            <OrderedList mb={4} pl={5}>
              {domToReact(children, options)}
            </OrderedList>
          );
        case 'li':
          return <ListItem>{domToReact(children, options)}</ListItem>;
        case 'code':
          return (
            <Code bg={codeColor} p={1} borderRadius="md">
              {domToReact(children, options)}
            </Code>
          );
        case 'blockquote':
          return (
            <Box
              as="blockquote"
              borderLeft="4px solid"
              borderColor="gray.300"
              pl={4}
              my={4}
              fontStyle="italic"
              color={blockColor}
            >
              {domToReact(children, options)}
            </Box>
          );
        case 'img':
          return (
            <Image
              src={attribs.src}
              alt={attribs.alt || 'Image'}
              my={4}
              borderRadius="md"
              boxShadow="md"
              maxW="100%"
              objectFit="cover"
            />
          );
        default:
          return undefined;
      }
    },
  };

  // Social sharing URLs
  const shareUrl = window.location.href;
  const shareText = encodeURIComponent(`Check out this article: ${post.title.rendered}`);
  const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
  const twitterShare = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${shareText}`;
  const linkedinShare = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${shareText}`;

  // Smooth scroll to the heading on TOC click
  const handleTOCClick = (id) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Box py={10} px={{ base: 5, md: 16 }} maxW="1200px" mx="auto">
      <Grid templateColumns={{ base: '1fr', md: '250px 1fr' }} gap={8}>
        {/* Left Panel */}
        <Box
          // Make the left panel sticky
          position={{ base: 'static', md: 'sticky' }}
          top={8}
          alignSelf="start"
          // For additional padding at the bottom so it doesn't cut off
          pb={4}
        >
          {/* Author Details */}
          {author && (
            <Box mb={8}>
              <Heading as="h3" size="md" mb={4}>
                About the Author
              </Heading>
              <HStack spacing={4} align="start">
                <Avatar src={authorAvatar} size="lg" name={author.name} />
                <VStack align="start" spacing={1}>
                  <Text fontWeight="bold">{author.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {author.description || 'Author bio not available.'}
                  </Text>
                </VStack>
              </HStack>
            </Box>
          )}

          {/* Share Section */}
          <Box mb={8}>
            <Heading as="h3" size="md" mb={4}>
              Share this post
            </Heading>
            <HStack spacing={4}>
              <IconButton
                as="a"
                href={facebookShare}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Facebook"
                icon={<FaFacebook />}
                colorScheme="facebook"
                variant="ghost"
              />
              <IconButton
                as="a"
                href={twitterShare}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Twitter"
                icon={<FaTwitter />}
                colorScheme="twitter"
                variant="ghost"
              />
              <IconButton
                as="a"
                href={linkedinShare}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on LinkedIn"
                icon={<FaLinkedin />}
                colorScheme="linkedin"
                variant="ghost"
              />
            </HStack>
          </Box>

          {/* Next Articles */}
          {nextPosts.length > 0 && (
            <Box mb={8}>
              <Heading as="h3" size="md" mb={4}>
                Next Articles
              </Heading>
              <VStack align="start" spacing={3}>
                {nextPosts.map((nextPost) => (
                  <ChakraLink
                    as={RouterLink}
                    to={`/blog/${nextPost.slug}`}
                    key={nextPost.id}
                    _hover={{ textDecoration: 'underline', color: 'teal.500' }}
                  >
                    {nextPost.title.rendered}
                  </ChakraLink>
                ))}
              </VStack>
            </Box>
          )}

          {/* Table of Contents */}
          {headings.length > 0 && (
            <Box mb={8}>
              <Heading as="h3" size="md" mb={4}>
                Table of Contents
              </Heading>
              <VStack align="start" spacing={2}>
                {headings.map((heading) => (
                  <ChakraLink
                    key={heading.id}
                    onClick={() => handleTOCClick(heading.id)}
                    cursor="pointer"
                    // Highlight if active
                    fontWeight={heading.id === activeHeadingId ? 'bold' : 'normal'}
                  >
                    {heading.text}
                  </ChakraLink>
                ))}
              </VStack>
            </Box>
          )}
        </Box>

        {/* Right Panel - Blog Content */}
        <Box>
          <Heading
            mb={4}
            as="h1"
            size="2xl"
            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          />
          <HStack spacing={2} mb={6}>
            <Text fontSize="sm" color="gray.500">
              By {author ? author.name : 'Unknown Author'}
            </Text>
            <Divider orientation="vertical" />
            <Text fontSize="sm" color="gray.500">
              {publishDate}
            </Text>
          </HStack>
          {featuredImage && (
            <Image
              src={featuredImage}
              alt={post.title.rendered}
              borderRadius="md"
              boxShadow="md"
              maxH="450px"
              objectFit="cover"
              mb={6}
            />
          )}
          <Box color={textColor} mb={8}>
            {parse(post.content.rendered, options)}
          </Box>

          {/* Optional: Author Bio at the End */}
          {author && author.description && (
            <Box mt={10} p={5} bg={authorBlockColor} borderRadius="md">
              <HStack spacing={4} align="start">
                <Avatar src={authorAvatar} size="md" name={author.name} />
                <VStack align="start" spacing={1}>
                  <Text fontWeight="bold">{author.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {author.description}
                  </Text>
                </VStack>
              </HStack>
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  );
}
