import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import MainSection from '../components/Home/MainSection';
import FeatureSection from '../components/Home/FeatureSection';
import StepsSection from '../components/Home/StepsSection';
import PlanSection from '../components/Home/PlanSection';
import FAQSection from '../components/Home/FAQSection';

import ApiClient from '../utils/API/api';
import ComparisonSection from "../components/Home/ComparisonSection";
import TestimonialSection from "../components/Home/TestimonialSection";
import InteractiveMap from "../components/Home/InteractiveMap"; // If/when you want to use

import MidCTASection from '../components/Home/MidCTASection';
// import PricingSection from '../components/Home/PricingSection';
import RewardsSection from '../components/Home/RewardsSection';
import FinalCTASection from '../components/Home/FinalCTASection';

function Home() {
  useEffect(() => {
    const fetchData = async () => {
      const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => { return ""; });
      try {
        const response = await apiClient.get('/plans');
        // handle response if needed
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box>
      {/* 1. Hero Section */}
      <MainSection />

      {/* 2. Feature Section */}
      <FeatureSection />

      {/* 3. Steps Section */}
      <StepsSection />

      {/* 4. NEW: Comparison (Savings) Section */}
      <ComparisonSection />

      {/* 5. NEW: Testimonials Section */}
      <TestimonialSection />

      {/* 6. Plan Section */}
      <PlanSection />

      {/* 7. Interactive Map */}
      <InteractiveMap />

      {/* 7. Bold CTA */}
      <MidCTASection />

      {/* 8. Pricing & Plan Cards */}
      {/* <PricingSection /> */}

      {/* 9. Rewards & Loyalty */}
      <RewardsSection />

      {/* 10. Final CTA */}
      <FinalCTASection />


      {/* 7. FAQ Section */}
      <FAQSection />
    </Box>
  );
}

export default Home;
