// src/components/Home/ComparisonSection.js

import React, { useState } from 'react';
import { Box, Flex, Heading, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Button, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const ComparisonSection = () => {
  const [dataUsage, setDataUsage] = useState(5); // in GB
  const roamingCost = dataUsage * 10; // e.g., $10 per GB for roaming
  const nesaCost = dataUsage * 2; // e.g., $3 per GB for Nesa

  const navigate = useNavigate();

  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.800')}
      py="20"
      px="5"
    >
      <Flex
        direction="column"
        align="center"
        maxW="1000px"
        mx="auto"
        textAlign="center"
      >
        <Heading size="xl" mb="6">
          See How Much You Save
        </Heading>
        <Text mb="8" fontSize="lg">
          Adjust your approximate data usage and instantly compare your estimated costs.
        </Text>

        {/* Slider */}
        <Box width={{ base: '90%', md: '50%' }} mb="8">
          <Text mb="2" fontWeight="bold">Data Usage: {dataUsage}GB</Text>
          <Slider
            defaultValue={dataUsage}
            min={1}
            max={30}
            step={1}
            onChange={(val) => setDataUsage(val)}
            colorScheme="purple"
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Box>

        {/* Comparison Results */}
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="center"
          align="center"
          gap="6"
          mb="8"
        >
          <Box
            bg="gray.100"
            p="6"
            borderRadius="md"
            minW="250px"
          >
            <Text fontSize="xl" fontWeight="bold" mb="2">Traditional Roaming</Text>
            <Text fontSize="2xl">${roamingCost.toFixed(2)}</Text>
          </Box>
          <Box
            bg="gray.100"
            p="6"
            borderRadius="md"
            minW="250px"
          >
            <Text fontSize="xl" fontWeight="bold" mb="2">Nesa Mobile</Text>
            <Text fontSize="2xl" color="green.600">~${nesaCost.toFixed(2)}</Text>
          </Box>
        </Flex>

        <Button
          colorScheme="purple"
          size="lg"
          onClick={() => navigate('/plans')}
          borderRadius={'5rem'}
        >
          Start Saving Now
        </Button>
      </Flex>
    </Box>
  );
};

export default ComparisonSection;
