// src/components/Home/RewardsSection.js

import React from 'react';
import { Box, Flex, Heading, Text, Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { FaAward, FaGift, FaUsers } from 'react-icons/fa';

const RewardsSection = () => {

  return (
    <Box bg={useColorModeValue('gray.50', 'gray.800')}
      p="6"
      // borderRadius="md"
      // boxShadow="md"
      marginTop={10}
      marginBottom={10}
    >
      <Flex direction="column" align="center" maxW="1000px" mx="auto">
        <Heading size="xl" mb="4">
          Earn Points & Unlock Perks
        </Heading>
        <Text fontSize="lg" mb="12" maxW="600px" textAlign="center">
          Every plan you purchase earns you NesaPoints—redeem them for discounted data, special offers, and more.
        </Text>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} w="100%">
          {/* Example Reward 1 */}
          <Box
            bg="white"
            p="6"
            borderRadius="md"
            boxShadow="md"
            textAlign="center"
          >
            <Icon as={FaAward} w={10} h={10} color="purple.700" mb="4" />
            <Heading size="md" mb="2">NesaPoints on Every Purchase</Heading>
            <Text>Earn points with each new data plan. Collect enough and enjoy free upgrades!</Text>
          </Box>

          {/* Example Reward 2 */}
          <Box
            bg="white"
            p="6"
            borderRadius="md"
            boxShadow="md"
            textAlign="center"
          >
            <Icon as={FaGift} w={10} h={10} color="purple.700" mb="4" />
            <Heading size="md" mb="2">Referral Bonuses</Heading>
            <Text>Invite friends to Nesa Mobile. Give 100 Points, get 100 Points — everyone wins!</Text>
          </Box>

          {/* Example Reward 3 */}
          <Box
            bg="white"
            p="6"
            borderRadius="md"
            boxShadow="md"
            textAlign="center"
          >
            <Icon as={FaUsers} w={10} h={10} color="purple.700" mb="4" />
            <Heading size="md" mb="2">Exclusive Community Perks</Heading>
            <Text>Access members-only discounts and events for frequent travelers.</Text>
          </Box>
        </SimpleGrid>
      </Flex>
    </Box>
  );
};

export default RewardsSection;
