// src/components/General/Footer.js

import React, { useState, useEffect } from 'react';
import {
	Box,
	Center,
	Text,
	Flex,
	Link,
	Icon,
	Stack,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	useBreakpointValue,
	Image
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { FaInstagram, FaWhatsapp, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import TermsOfService from './Footer/terms';
import PrivacyPolicy from './Footer/privacy';
import CurrencySelector from './Footer/CurrencySelector';

import Intercom from '@intercom/messenger-js-sdk';
import ApiClient from '../utils/API/api';
import { useUser, useAuth } from '@clerk/clerk-react';

const Footer = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { user, isLoaded } = useUser();
	const { getToken } = useAuth();
	const isMobile = useBreakpointValue({ base: true, md: false });
	const [modalDetails, setModalDetails] = useState({
		title: '',
		description: <Text>Hello world</Text>,
		size: 'md',
	});

	const [userData, setUserData] = useState(null);

	useEffect(() => {
		const fetchUserData = async () => {
			if (!user) return;

			try {
				const token = await getToken();
				const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => token);
				const result = await apiClient.get('user');
				setUserData(result.data);
			} catch (error) {
				console.log('User not logged in or error fetching user data');
			}
		};

		fetchUserData();
	}, [user, getToken]);

	useEffect(() => {
		console.log(userData);
		Intercom({
			app_id: 'a2h4r9jl',
			...(userData && {
				name: user.firstName,
				email: userData.user.email,
				user_id: userData.user._id,
				created_at: new Date(userData.user.created_at).getTime() / 1000,
				user_hash: userData.intercomHash
			}),
		});
	}, [userData]);

	const [currentCurrency, setCurrentCurrency] = useState(() => {
		const storedCurrency = localStorage.getItem('currency') || 'USD';
		return storedCurrency;
	});

	return (
		<Box bg="gray.900" color="white" py={10} mt={10}>
			<Modal isOpen={isOpen} onClose={onClose} size={modalDetails.size}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{modalDetails.title}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>{modalDetails.description}</ModalBody>
					<ModalFooter>
						<Button colorScheme="purple" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Center>
				<Flex
					direction={{ base: 'column', md: 'row' }}
					align="start"
					justify="space-between"
					maxW="1200px"
					w="full"
					px={4}
				>
					{/* Logo & Description */}
					<Box flex="1" mb={{ base: 6, md: 0 }}>
						<Flex
							alignItems="center"
							cursor="pointer"
							mb={4}
							onClick={() => window.location.href = '/'}
						>
							<Image
								src="/assets/images/logo_transparent.png"
								alt="Nesa Mobile"
								h="50px"
								mr={3}
							/>
							<Text fontSize="lg" fontWeight="bold">Nesa Mobile</Text>
						</Flex>
						<Text fontSize="sm" color="gray.300">
							At Nesa Mobile, we connect travelers with the world effortlessly.
							Reliable, affordable eSIM solutions for everyone.
						</Text>
					</Box>

					{/* Menu Links */}
					<Flex flex="2" justify="space-around" wrap="wrap">
						<Stack spacing={2} minW="150px" mb={{ base: 6, md: 0 }}>
							<Text fontWeight="bold" mb={1}>Company</Text>
							<Link as={ReactRouterLink} to="/about">About Us</Link>
							<Link as={ReactRouterLink} to="/contact">Support</Link>
						</Stack>

						<Stack spacing={2} minW="150px" mb={{ base: 6, md: 0 }}>
							<Text fontWeight="bold" mb={1}>Legal</Text>
							<Link
								variant="link"
								onClick={() => {
									setModalDetails({
										title: 'Terms of Service',
										description: <TermsOfService />,
										size: 'full',
									});
									onOpen();
								}}
							>
								Terms of Service
							</Link>
							<Link
								variant="link"
								onClick={() => {
									setModalDetails({
										title: 'Privacy Policy',
										description: <PrivacyPolicy />,
										size: 'full',
									});
									onOpen();
								}}
							>
								Privacy Policy
							</Link>
						</Stack>

						<Stack spacing={2} minW="150px">
							<Text fontWeight="bold" mb={1}>Settings</Text>
							<Link
								variant="link"
								onClick={() => {
									setModalDetails({
										title: 'Change Language',
										description: <Text>Sorry, this feature is coming soon!</Text>,
										size: 'md',
									});
									onOpen();
								}}
							>
								Language: English
							</Link>
							<Link
								variant="link"
								onClick={() => {
									setModalDetails({
										title: 'Change Currency',
										description: <CurrencySelector />,
										size: 'md',
									});
									onOpen();
								}}
							>
								Currency: {currentCurrency}
							</Link>
						</Stack>
					</Flex>
				</Flex>
			</Center>

			{/* Social & Copyright */}
			<Box mt={8} textAlign="center">
				<Stack direction="row" spacing={4} justify="center" mb={4}>
					<Link href="https://x.com/nesamobile" isExternal>
						<Icon as={FaXTwitter} w={6} h={6} />
					</Link>
					<Link href="https://instagram.com/nesamobilecom" isExternal>
						<Icon as={FaInstagram} w={6} h={6} />
					</Link>
					<Link href="https://wa.me/message/Z6CYYQEBRG5XM1" isExternal>
						<Icon as={FaWhatsapp} w={6} h={6} />
					</Link>
					<Link href="https://www.linkedin.com/company/nesamobile" isExternal>
						<Icon as={FaLinkedin} w={6} h={6} />
					</Link>
				</Stack>
				<Text fontSize="sm" color="gray.500">
					© 2024 Nesa eSIM. All rights reserved.
				</Text>
			</Box>
		</Box>
	);
};

export default Footer;
