import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
	Box,
	Flex,
	Image,
	Heading,
	Text,
	Stack,
	Grid,
	Button,
	useBreakpointValue,
	Tabs,
	TabPanel,
	TabPanels,
	TabList,
	Tab,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	useColorModeValue,
	Select,
	Divider
} from '@chakra-ui/react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link as ReactRouterLink } from 'react-router-dom';

import ApiClient from '../../utils/API/api';
import countryUtils from '../../utils/Plans/country';
import { filterAndSortPackages } from '../../utils/Plans/plans';

import PlanCard from './PlanCard';
import CountryFlag from './CountryFlag.js';
import countryImagesMapping from './countryImagesMapping.js';
import StepsSection from '../Home/StepsSection.js';

const Country = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [plans, setPlans] = useState([]);
	const [countryPlans, setCountryPlans] = useState([]);

	const { countryCode } = useParams();
	const navigate = useNavigate();

	const isMobile = useBreakpointValue({ base: true, md: false });

	// The main background for the container
	const containerBg = useColorModeValue('white', 'gray.800');
	// We might want a lighter color on dark mode so text is easier to read
	const textColor = useColorModeValue('gray.600', 'gray.300');
	const bg = useColorModeValue('gray.100', 'gray.700');

	// Country data
	const countryName = countryUtils.getName(countryCode.toUpperCase());
	const countryImageData =
		countryImagesMapping.find((c) => c.country === countryName) ??
		countryImagesMapping[0];

	// Tab segmentation
	const [segments, setSegments] = useState([
		{ label: 'Reloadable', shown: false },
		{ label: 'Non-reloadable', shown: false },
		{ label: 'Unlimited', shown: false },
	]);

	// Optional sort logic
	const [sortOption, setSortOption] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => '');
				const response = await apiClient.get('/plans');
				setPlans(response.data);

				// Filter out only the plans relevant to this country
				const allCountryPlans = response.data.obj.packageList.filter(
					(plan) => plan.location === countryCode.toUpperCase()
				);
				const sortedPlans = filterAndSortPackages(allCountryPlans, countryCode.toUpperCase());
				setCountryPlans(sortedPlans);

				// Determine which segment tabs should be visible
				allCountryPlans.forEach((plan) => {
					if (!plan.slug.endsWith('_Daily') && !plan.packageCode.endsWith('_End')) {
						setSegments((prev) => {
							const updated = [...prev];
							updated[0].shown = true; // Reloadable
							return updated;
						});
					}
					if (plan.slug.endsWith('_End')) {
						setSegments((prev) => {
							const updated = [...prev];
							updated[1].shown = true; // Non-reloadable
							return updated;
						});
					}
					if (plan.slug.endsWith('_Daily')) {
						setSegments((prev) => {
							const updated = [...prev];
							updated[2].shown = true; // Unlimited
							return updated;
						});
					}
				});

				setIsLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [countryCode]);

	// Filtering by plan type
	const filterPlans = (type) => {
		switch (type) {
			case 'Reloadable':
				return countryPlans.filter(
					(plan) => !plan.slug.endsWith('_Daily') && !plan.slug.endsWith('_End')
				);
			case 'Non-reloadable':
				return countryPlans.filter((plan) => plan.slug.endsWith('_End'));
			case 'Unlimited':
				return countryPlans.filter((plan) => plan.slug.endsWith('_Daily'));
			default:
				return countryPlans;
		}
	};

	// Sorting logic
	const handleSort = (plansArray) => {
		if (sortOption === 'priceLowHigh') {
			return [...plansArray].sort((a, b) => a.price - b.price);
		}
		if (sortOption === 'priceHighLow') {
			return [...plansArray].sort((a, b) => b.price - a.price);
		}
		if (sortOption === 'dataHighLow') {
			return [...plansArray].sort((a, b) => b.volume - a.volume);
		}
		return plansArray; // no sort selected
	};

	// Render final array
	const renderPlans = (segmentLabel) => {
		const filtered = filterPlans(segmentLabel);
		const sorted = handleSort(filtered);

		if (!sorted.length) {
			return <Text>No plans available</Text>;
		}

		return (
			<Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6}>
				{sorted.map((plan) => (
					<PlanCard key={plan.packageCode} plan={plan} />
				))}
			</Grid>
		);
	};

	if (isLoading) {
		return (
			<Box py="20" minHeight={'100vh'} px="10">
				<Flex direction="column" align="center" maxW="1200px" mx="auto">
					<Heading size="xl" mb="10">
						Loading...
					</Heading>
				</Flex>
			</Box>
		);
	}

	if (!countryPlans.length) {
		return (
			<Box py="50" minHeight={'100vh'} px="10">
				<Flex direction="column" align="center" maxW="1200px" mx="auto">
					<Heading size="lg" mb="6">Coming Soon</Heading>
					<Text fontSize="md">
						We're currently working to provide plans in {countryName}. Please check back soon!
					</Text>
				</Flex>
			</Box>
		);
	}

	return (
		<>
			<Box py="5" px="10">
				{/* Breadcrumb */}
				<Flex gap={5} mb={10} maxW="1200px" mx="auto" align="center">
					<Breadcrumb spacing="8px" separator={<RiArrowRightSLine color="gray.500" />}>
						<BreadcrumbItem>
							<BreadcrumbLink as={ReactRouterLink} to="/plans">
								Plans
							</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbItem isCurrentPage>
							<BreadcrumbLink href="#">{countryName}</BreadcrumbLink>
						</BreadcrumbItem>
					</Breadcrumb>
				</Flex>

				{/* Main Container */}
				<Flex
					direction={{ base: 'column', md: 'row' }}
					gap={8}
					maxW="1200px"
					mx="auto"
					my="5"
					bg={containerBg}
					borderRadius="xl"
					p={{ base: 5, md: 10 }}
					boxShadow="md"
				>
					{/* Country Image - fixed or max size */}
					{!isMobile && countryImageData?.fileName && (
						<Box
							flex="1"
							pos="relative"
							maxW="400px"         // prevents stretching beyond 400px
							w="100%"
							h="auto"
						>
							<Image
								w="100%"
								maxH="500px"       // cap the height
								objectFit="cover"
								src={countryImageData.fileName}
								alt={countryName}
								borderTopRadius={'2xl'}
							/>
							<Box
								w="100%"
								p="3"
								bg="rgba(0, 0, 0, 0.7)"
								borderBottomRadius="2xl"
							>
								<Text fontSize="xs" color="white">
									Photo by{' '}
									<a
										style={{ textDecoration: 'underline', color: 'white' }}
										target="_blank"
										rel="noreferrer"
										href={countryImageData.photographerProfile}
									>
										{countryImageData.photographerName}
									</a>{' '}
									from{' '}
									<a
										style={{ textDecoration: 'underline', color: 'white' }}
										target="_blank"
										rel="noreferrer"
										href={countryImageData.unsplashLink}
									>
										Unsplash
									</a>
								</Text>
							</Box>
						</Box>
					)}

					{/* Right-side content */}
					<Box flex="2">
						<Flex alignItems="center" gap={3} mb={4}>
							<CountryFlag isoCode={countryCode} size={32} />
							<Heading size="lg">Stay Connected in {countryName}</Heading>
						</Flex>

						<Text fontWeight="500" color={textColor} mb={4}>
							Enjoy seamless and affordable internet access—without hunting for local SIM cards or
							dealing with unpredictable roaming fees.
						</Text>

						<Divider my={4} />

						{/* Sorting options */}
						<Flex alignItems="center" gap={3} mb={2} flexWrap="wrap">
							<Text fontWeight="medium" color={textColor}>
								Sort By:
							</Text>
							<Select
								width="200px"
								onChange={(e) => setSortOption(e.target.value)}
								value={sortOption}
								size="sm"
							>
								<option value="">Default</option>
								<option value="priceLowHigh">Price: Low to High</option>
								<option value="priceHighLow">Price: High to Low</option>
								<option value="dataHighLow">Data: High to Low</option>
							</Select>
						</Flex>

						{/* Plan Type Tabs */}
						<Stack spacing="8" w="100%">
							<Tabs
								variant="soft-rounded"
								colorScheme="purple"
								mt={4}
							// Ensure no overflow on mobile
							>
								<TabList flexWrap="wrap" overflowX="auto">
									{segments
										.filter((segment) => segment.shown)
										.map((segment) => (
											<Tab
												key={segment.label}
												whiteSpace="nowrap"  // keep text on one line
												_hover={{ bg }}
											>
												{segment.label}
											</Tab>
										))}
								</TabList>
								<TabPanels>
									{segments
										.filter((segment) => segment.shown)
										.map((segment) => (
											<TabPanel key={segment.label} pt={6} px={0}>
												<Text fontSize="sm" mb="4" color={textColor}>
													{segment.label === 'Reloadable' &&
														"Flexible eSIMs you can recharge anytime to stay connected."}
													{segment.label === 'Non-reloadable' &&
														"One-time eSIMs perfect for short trips or specific needs."}
													{segment.label === 'Unlimited' &&
														"Select the amount of high-speed data you want daily, then continue at lower speeds afterward."}
												</Text>
												{renderPlans(segment.label)}
											</TabPanel>
										))}
								</TabPanels>
							</Tabs>

							{/* CTA: "View More Destinations" */}
							<Button
								mt="4"
								colorScheme="purple"
								onClick={() => navigate('/plans')}
								alignSelf="flex-start"
								borderRadius={'full'}
							>
								View More Destinations
							</Button>
						</Stack>
					</Box>
				</Flex>

			</Box>
			<StepsSection />
		</>
	);
};

export default Country;
