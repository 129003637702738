import React, { useState, useEffect } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  Text,
  Button,
  Box,
  Flex,
  Divider,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
  Badge,
} from '@chakra-ui/react';
import { useUser, useAuth, SignIn } from '@clerk/clerk-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { formatVolume } from '../../utils/Plans/plans';
import ApiClient from '../../utils/API/api';
import { useNavigate } from 'react-router-dom';
import { trackPlanEvent, trackTransaction, trackEvent } from '../../utils/analytics';


// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Create a separate PaymentForm component
const PaymentForm = ({ clientSecret, onPaymentComplete, plan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    setLoading(true);
    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/dashboard`,
        },
      });

      if (error) {
        toast({
          title: 'Payment Failed',
          description: error.message,
          status: 'error',
        });

        // Track failed payment with GA4 parameters
        trackEvent('payment_error', {
          error_message: error.message,
          currency: 'USD',
          value: plan.price / 100,
          payment_type: 'stripe',
          items: [{
            item_id: plan.id,
            item_name: plan.name,
            price: plan.price / 100,
            currency: 'USD'
          }]
        });
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      toast({
        title: 'Error',
        description: 'Failed to process payment',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box mt={4}>
        <PaymentElement />
      </Box>
      <DrawerFooter>
        <Button
          colorScheme="purple"
          size="lg"
          width="full"
          borderRadius="full"
          onClick={handleSubmit}
          isLoading={loading}
          loadingText="Processing..."
          isDisabled={!stripe || !elements}
        >
          Complete Purchase
        </Button>
      </DrawerFooter>
    </>
  );
};

const OrderDrawer = ({ isOpen, onClose, plan }) => {
  const { user, isLoaded } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    packageCode: plan?.slug,
    type: plan?.slug?.endsWith('_Daily') ? 'TOPUP' : 'BASE',
    isDaily: plan?.slug?.endsWith('_Daily'),
    totalDays: 1,
    discounts: {
      useCoupon: true,
      usePoints: false,
      maxPoints: 0,
      redeemablePoints: 0,
      selectedPoints: 0,
      notEnoughPoints: false,
      isCustomerGoodWillScenario: false,
      couponCode: '',
      errorMessage: '',
      successMessage: '',
    },
  });

  const [userData, setUserData] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  // Fetch user data useEffect
  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return; // Add early return here instead

      try {
        const token = await getToken();
        const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => token);
        const result = await apiClient.get('user');
        setUserData(result.data);

        // Update order details with user points
        const price = plan.price / 100;
        const points = Math.min(result.data.user.points, price);

        setOrderDetails(prev => ({
          ...prev,
          discounts: {
            ...prev.discounts,
            maxPoints: result.data.user.points,
            redeemablePoints: points,
          }
        }));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [user, getToken, plan]);

  // Handle coupon validation
  const validateCoupon = async () => {
    if (!orderDetails.discounts.couponCode) return;

    setLoading(true);
    try {
      const token = await getToken();
      const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => token);
      const result = await apiClient.post('coupon', {
        couponCode: orderDetails.discounts.couponCode,
        planSlug: plan.slug,
      });

      if (result.data.success) {
        setOrderDetails(prev => ({
          ...prev,
          discounts: {
            ...prev.discounts,
            couponDiscountType: result.data.coupon.type,
            couponDiscountValue: result.data.coupon.value,
            errorMessage: '',
            successMessage: 'Coupon applied successfully!',
          }
        }));
      } else {
        setOrderDetails(prev => ({
          ...prev,
          discounts: {
            ...prev.discounts,
            couponCode: '',
            errorMessage: result.data.message,
            successMessage: '',
          }
        }));
      }
    } catch (error) {
      console.error('Error validating coupon:', error);
      toast({
        title: 'Error',
        description: 'Failed to validate coupon',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // Get payment parameters from backend
  const getPaymentParameters = async () => {
    try {
      const token = await getToken();
      const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => token);
      const result = await apiClient.post('payment-parameters', { orderDetails });

      if (result.data.errors?.length > 0) {
        toast({
          title: 'Validation Error',
          description: result.data.errors.join(', '),
          status: 'error',
        });
        return false;
      }

      // If it's a free plan or customer goodwill scenario
      if (result.data.finalPrice === 0 || result.data.customerGoodWill) {
        toast({
          title: 'Success',
          description: 'Order processed successfully!',
          status: 'success',
        });
        onClose();
        navigate('/dashboard');
        return false;
      }

      setClientSecret(result.data.paymentIntent);
      setShowPaymentForm(true);
      return true;
    } catch (error) {
      console.error('Error getting payment parameters:', error);
      toast({
        title: 'Error',
        description: 'Failed to initialize payment',
        status: 'error',
      });
      return false;
    }
  };

  // Add the formatPrice helper function (same as PlanCard)
  const formatPrice = (price) => {
    const currency = localStorage.getItem('currency') || 'USD';
    const rate = parseFloat(localStorage.getItem('conversionRate') || 1);
    const convertedPrice = price * rate;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency
    }).format(convertedPrice);
  };

  // Update calculateTotal to handle points correctly (1 point = $0.01)
  const calculateTotal = () => {
    let total = plan.price;

    // Apply points discount (each point is worth $0.01 = 100 in our price scale)
    if (orderDetails.discounts.usePoints) {
      total -= orderDetails.discounts.selectedPoints * 100; // 1 point = $0.01 = 100 in price scale
    }

    // Apply coupon discount
    if (orderDetails.discounts.useCoupon && orderDetails.discounts.couponDiscountValue) {
      if (orderDetails.discounts.couponDiscountType === 'percentage') {
        total *= (1 - orderDetails.discounts.couponDiscountValue / 100);
      } else {
        total -= orderDetails.discounts.couponDiscountValue * 10000; // Fixed amount coupons are in dollars
      }
    }

    // Apply daily multiplier if applicable
    if (orderDetails.isDaily) {
      total *= orderDetails.totalDays;
    }

    return Math.max(total, 0);
  };

  // Handle purchase
  const handlePurchase = async () => {
    // Track begin checkout with GA4 parameters
    trackEvent('begin_checkout', {
      currency: 'USD',
      value: plan.price / 100,
      items: [{
        item_id: plan.id,
        item_name: plan.name,
        price: plan.price / 100,
        currency: 'USD'
      }],
      coupon: orderDetails.discounts.couponCode || undefined,
      payment_type: calculateTotal() === 0 ? 'points' : 'stripe'
    });

    const success = await getPaymentParameters();
    if (!success) {
      // Track checkout error with GA4 parameters
      trackEvent('checkout_error', {
        error_message: 'Failed to initialize payment',
        currency: 'USD',
        value: plan.price / 100,
        items: [{
          item_id: plan.id,
          item_name: plan.name,
          price: plan.price / 100,
          currency: 'USD'
        }]
      });
    }
  };

  // Render different content based on auth state
  const renderDrawerContent = () => {
    if (!isLoaded) {
      return (
        <Stack p={6} spacing={4} align="center">
          <Text>Loading...</Text>
        </Stack>
      );
    }

    if (!user) {
      return (
        <Stack p={6} spacing={4} align="center">
          <Text fontSize="lg" fontWeight="bold">Sign in to Purchase</Text>
          <Text color="gray.600" textAlign="center">
            Please sign in or create an account to purchase this plan
          </Text>
          <Box w="full" maxW="400px" mx="auto">
            <SignIn
              signInRedirectUrl={window.location.href}
              appearance={{
                elements: {
                  rootBox: {
                    width: '100%',
                    margin: '0 auto',
                  },
                  card: {
                    border: '1px solid #E2E8F0',
                    borderRadius: '1rem',
                    boxShadow: 'none',
                  }
                }
              }}
            />
          </Box>
        </Stack>
      );
    }

    return (
      <>
        <DrawerHeader>
          <Stack spacing={1}>
            <Text>{formatVolume(plan?.volume)} Data Plan</Text>
            <Text fontSize="sm" color="gray.500" fontWeight="normal">
              {plan?.slug?.endsWith('_Daily')
                ? 'Daily High-Speed Data'
                : plan?.slug?.endsWith('_End')
                  ? 'Non-Reloadable Plan'
                  : 'Reloadable Plan'
              }
            </Text>
          </Stack>
        </DrawerHeader>

        <DrawerBody>
          <Stack spacing={6}>
            {/* Plan Details */}
            <Box>
              <Text fontWeight="semibold" mb={2}>Plan Details</Text>
              <Stack spacing={3}>
                <Flex justify="space-between">
                  <Text color="gray.500">Data Allowance</Text>
                  <Text>{formatVolume(plan?.volume)}</Text>
                </Flex>
                <Flex justify="space-between">
                  <Text color="gray.500">Duration</Text>
                  <Text>{plan?.duration} {plan?.duration > 1 ? 'Days' : 'Day'}</Text>
                </Flex>
                {orderDetails.isDaily && (
                  <Flex justify="space-between">
                    <Text color="gray.500">Total Days</Text>
                    <Box>
                      <Button size="sm" onClick={() => setOrderDetails(prev => ({
                        ...prev,
                        totalDays: Math.max(1, prev.totalDays - 1)
                      }))}>-</Button>
                      <Text display="inline" mx={3}>{orderDetails.totalDays}</Text>
                      <Button size="sm" onClick={() => setOrderDetails(prev => ({
                        ...prev,
                        totalDays: Math.min(30, prev.totalDays + 1)
                      }))}>+</Button>
                    </Box>
                  </Flex>
                )}
              </Stack>
            </Box>

            <Divider />

            {/* Discounts Section */}
            <Box>
              <Flex justify="space-between" align="center" mb={4}>
                <Text fontWeight="semibold">
                  Apply {orderDetails.discounts.usePoints ? 'Points' : 'Coupon'}
                </Text>
                <Button
                  variant="link"
                  onClick={() => setOrderDetails(prev => ({
                    ...prev,
                    discounts: {
                      ...prev.discounts,
                      useCoupon: !prev.discounts.useCoupon,
                      usePoints: !prev.discounts.usePoints,
                      couponCode: '',
                      errorMessage: '',
                      successMessage: '',
                    }
                  }))}
                >
                  Use {orderDetails.discounts.usePoints ? 'Coupon' : 'Points'}
                </Button>
              </Flex>

              {orderDetails.discounts.usePoints ? (
                <Box>
                  <Slider
                    value={orderDetails.discounts.selectedPoints}
                    min={0}
                    max={orderDetails.discounts.redeemablePoints}
                    onChange={(value) => setOrderDetails(prev => ({
                      ...prev,
                      discounts: {
                        ...prev.discounts,
                        selectedPoints: value,
                      }
                    }))}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                  <Text textAlign="center" mt={2}>
                    Points: {orderDetails.discounts.selectedPoints}
                  </Text>
                </Box>
              ) : (
                <Flex gap={2}>
                  <Input
                    placeholder="Enter coupon code"
                    value={orderDetails.discounts.couponCode}
                    borderRadius="full"
                    onChange={(e) => setOrderDetails(prev => ({
                      ...prev,
                      discounts: {
                        ...prev.discounts,
                        couponCode: e.target.value,
                      }
                    }))}
                  />
                  <Button
                    onClick={validateCoupon}
                    isLoading={loading}
                    borderRadius="full"
                  >
                    Apply
                  </Button>
                </Flex>
              )}

              {orderDetails.discounts.errorMessage && (
                <Text color="red.500" mt={2}>{orderDetails.discounts.errorMessage}</Text>
              )}
              {orderDetails.discounts.successMessage && (
                <Text color="green.500" mt={2}>{orderDetails.discounts.successMessage}</Text>
              )}
            </Box>

            <Divider />

            {/* Order Summary */}
            <Box>
              <Text fontWeight="semibold" mb={4}>Order Summary</Text>
              <Stack spacing={3}>
                <Flex justify="space-between">
                  <Text>eSIM Plan</Text>
                  <Text>{formatPrice(plan?.price / 10000)}</Text>
                </Flex>

                {orderDetails.discounts.usePoints && orderDetails.discounts.selectedPoints > 0 && (
                  <Flex justify="space-between">
                    <Text>Points Discount</Text>
                    <Text color="green.500">
                      -{formatPrice(orderDetails.discounts.selectedPoints / 100)}
                    </Text>
                  </Flex>
                )}

                {orderDetails.discounts.useCoupon && orderDetails.discounts.couponDiscountValue > 0 && (
                  <Flex justify="space-between">
                    <Text>Coupon Discount</Text>
                    <Text color="green.500">
                      -{formatPrice(
                        orderDetails.discounts.couponDiscountType === 'percentage'
                          ? (plan.price / 10000) * (orderDetails.discounts.couponDiscountValue / 100)
                          : orderDetails.discounts.couponDiscountValue
                      )}
                    </Text>
                  </Flex>
                )}

                <Divider />

                <Flex justify="space-between" fontWeight="bold">
                  <Text>Total</Text>
                  <Text>{formatPrice(calculateTotal() / 10000)}</Text>
                </Flex>
              </Stack>
            </Box>
          </Stack>
        </DrawerBody>

        {!showPaymentForm && (
          <DrawerFooter>
            <Button
              colorScheme="purple"
              size="lg"
              width="full"
              borderRadius="full"
              onClick={handlePurchase}
              loadingText="Processing..."
              isDisabled={orderDetails.discounts.notEnoughPoints}
            >
              {calculateTotal() === 0 ? 'Redeem Points' : 'Proceed to Payment'}
            </Button>
          </DrawerFooter>
        )}
      </>
    );
  };

  useEffect(() => {
    if (isOpen && plan) {
      // Track plan view with GA4 parameters
      trackEvent('view_item', {
        currency: 'USD',
        value: plan.price / 100,
        items: [{
          item_id: plan.id,
          item_name: plan.name,
          price: plan.price / 100,
          currency: 'USD'
        }]
      });
    }
  }, [isOpen, plan]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {showPaymentForm && clientSecret ? (
          <Elements stripe={stripePromise} options={{
            clientSecret,
            appearance: {
              theme: 'stripe',
            },
          }}>
            <PaymentForm
              clientSecret={clientSecret}
              onPaymentComplete={() => {
                setShowPaymentForm(false);
                onClose();
              }}
              plan={plan}
            />
          </Elements>
        ) : (
          renderDrawerContent()
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default OrderDrawer;