import { useState, useEffect } from 'react';
import {
  Box, Text, Button, Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';

import { getMobileOperatingSystem } from '../../utils/device_detection';

function DownloadApp({ triggerOpen, setTriggerOpen }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalDetails, setModalDetails] = useState({
    title: '',
    description: <Text>Hello world</Text>,
    size: 'md',
    showClose: false
  });

  useEffect(() => {
    if (triggerOpen) {
      const operatingSystem = getMobileOperatingSystem();
      if (operatingSystem === 'ios') {
        window.open("https://apps.apple.com/app/nesa-affordable-global-data/id6504453237");
      } else if (operatingSystem === 'android') {
        window.open("https://play.google.com/store/apps/details?id=com.nesamobile.app");
      } else {
        setModalDetails({
          title: 'Download the Nesa Mobile App',
          description:
            <Box>
              <p>Scan the QR Code on your mobile device to download the app!</p>
              <Center>
                <img src="/assets/images/nesa-mobile-app-download.png" alt="Nesa Mobile QR Code" />
              </Center>
            </Box>
          ,
          size: 'sm'
        });
        onOpen();
      }
    } else {
      setTriggerOpen(false);
      onClose();
    }
  }, [triggerOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => {
        setTriggerOpen(false);
        onClose();
      }} size={modalDetails.size}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalDetails.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalDetails.description}
          </ModalBody>

          {modalDetails.showClose && <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>}

        </ModalContent>
      </Modal>
    </>
  )
}

export default DownloadApp