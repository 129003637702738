import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  Card,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Badge,
  Spinner,
  Center,
  Text,
  Flex,
  Button,
  Select,
  useToast,
  SimpleGrid,
  Input,
  HStack,
  IconButton
} from '@chakra-ui/react';
import { useUser, useAuth } from '@clerk/clerk-react';
import ApiClient from '../utils/API/api';

import { FiChevronLeft, FiChevronRight, FiDownload } from 'react-icons/fi';

const TransactionHistory = () => {
  const { user } = useUser();
  const { getToken } = useAuth();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 50
  });
  const [pageSummary, setPageSummary] = useState({
    totalTransactions: 0,
    totalRevenue: 0,
    averageOrderValue: 0
  });
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  const fetchTransactions = async (page = 1) => {
    if (!user) return;

    try {
      setLoading(true);
      const token = await getToken();
      const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => token);

      const params = {
        page,
        limit: 50,
        ...(dateRange.startDate && { startDate: dateRange.startDate }),
        ...(dateRange.endDate && { endDate: dateRange.endDate })
      };

      const result = await apiClient.get('partner/transactions', { ...params }, false);

      setTransactions(result.data.transactions);
      setPagination(result.data.pagination);
      setPageSummary(result.data.pageSummary);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to load transactions',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchTransactions();
    }
  }, [user?.id]);

  const formatCurrency = (amount, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(priceFormatter(amount));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const priceFormatter = (price) => {
    return price / 100;
  }

  const handleDateChange = (field, value) => {
    setDateRange(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleFilter = () => {
    fetchTransactions(1); // Reset to first page when filtering
  };

  const downloadCSV = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const token = await getToken();
      const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => token);

      const params = {
        export: 'csv',
        ...(dateRange.startDate && { startDate: dateRange.startDate }),
        ...(dateRange.endDate && { endDate: dateRange.endDate })
      };

      const response = await apiClient.get('partner/transactions', {
        ...params,
        responseType: 'blob'
      }, false);

      // Create download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'transactions.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading CSV:', error);
      toast({
        title: 'Error',
        description: 'Failed to download CSV',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" color="purple.500" />
      </Center>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <Stack spacing={8}>
        <Heading size="lg">Transaction History</Heading>

        {/* Date Filter Controls */}
        <Card>
          <CardBody>
            <Stack spacing={4}>
              <Text fontWeight="medium">Filter Transactions</Text>
              <HStack spacing={2}>
                <Input
                  type="date"
                  value={dateRange.startDate}
                  onChange={(e) => handleDateChange('startDate', e.target.value)}
                  placeholder="Start Date"
                />
                <Input
                  type="date"
                  value={dateRange.endDate}
                  onChange={(e) => handleDateChange('endDate', e.target.value)}
                  placeholder="End Date"
                />

              </HStack>
              <HStack spacing={2} justify="flex-end">
                <Button onClick={handleFilter} colorScheme="purple">
                  Apply Filter
                </Button>
                <IconButton
                  icon={<FiDownload />}
                  onClick={downloadCSV}
                  aria-label="Download CSV"
                  title="Download CSV"
                  colorScheme="green"
                />
              </HStack>
            </Stack>
          </CardBody>
        </Card>

        {/* Page Summary */}
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6}>
          <Card>
            <CardBody>
              <Stat>
                <StatLabel>Total Transactions</StatLabel>
                <StatNumber>{pageSummary.totalTransactions}</StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Stat>
                <StatLabel>Total Revenue</StatLabel>
                <StatNumber>{formatCurrency(pageSummary.totalRevenue)}</StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Stat>
                <StatLabel>Average Order Value</StatLabel>
                <StatNumber>{formatCurrency(pageSummary.averageOrderValue)}</StatNumber>
              </Stat>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Stat>
                <StatLabel>Partner Commission</StatLabel>
                <StatNumber>{formatCurrency(parseFloat(pageSummary.totalCommission) * 100)}</StatNumber>
              </Stat>
            </CardBody>
          </Card>
        </SimpleGrid>

        {/* Transactions Table */}
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Customer</Th>
                <Th>Product</Th>
                <Th>Coupon</Th>
                <Th isNumeric>Base Price</Th>
                <Th isNumeric>Final Price</Th>
                <Th isNumeric>Commission</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transactions.map((transaction) => (
                <Tr key={transaction.transactionId}>
                  <Td>{formatDate(transaction.purchaseDate)}</Td>
                  <Td>{transaction.customerEmail}</Td>
                  <Td>
                    <Badge colorScheme={transaction.product.type === 'eSIM' ? 'blue' : 'green'}>
                      {transaction.product.type}
                    </Badge>
                  </Td>
                  <Td>
                    <Stack spacing={0}>
                      <Text fontWeight="medium">{transaction.coupon.code}</Text>
                      <Text fontSize="sm">
                        {transaction.coupon.type === 'percentage'
                          ? `${transaction.coupon.value}% off`
                          : `$${formatCurrency(priceFormatter(transaction.coupon.value))} off`}
                      </Text>
                    </Stack>
                  </Td>
                  <Td isNumeric>{formatCurrency(priceFormatter(transaction.product.basePrice), transaction.currency)}</Td>
                  <Td isNumeric>{formatCurrency(transaction.finalPrice, transaction.currency)}</Td>
                  <Td isNumeric>{formatCurrency(parseFloat(transaction.commission) * 100, transaction.currency)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        {/* Pagination Controls */}
        <Flex justify="center" align="center" gap={4}>
          <Button
            leftIcon={<FiChevronLeft />}
            onClick={() => fetchTransactions(pagination.currentPage - 1)}
            isDisabled={pagination.currentPage === 1}
          >
            Previous
          </Button>
          <Text>
            Page {pagination.currentPage} of {pagination.totalPages}
          </Text>
          <Button
            rightIcon={<FiChevronRight />}
            onClick={() => fetchTransactions(pagination.currentPage + 1)}
            isDisabled={pagination.currentPage === pagination.totalPages}
          >
            Next
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};

export default TransactionHistory; 