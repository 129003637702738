import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  Button,
  Spinner,
  useColorModeValue
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const cardBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  // Example WP endpoint
  const WP_API_URL = 'https://nesamobile.blog/wp-json/wp/v2/posts?_embed';
  // _embed sometimes includes featured images

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(WP_API_URL);
        const data = await response.json();
        setPosts(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };
    fetchPosts();
  }, []);

  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner color="purple.500" size="xl" />
      </Box>
    );
  }

  return (
    <Box py={10} px={5} maxW="1200px" mx="auto">
      <Heading mb={6}>Nesa Blog</Heading>
      <VStack spacing={8} align="stretch">
        {posts.map((post) => {
          // Attempt to get the featured image if available
          const featuredImage =
            post._embedded?.['wp:featuredmedia']?.[0]?.source_url || '/assets/images/blog_placeholder.jpg';

          return (
            <Box
              key={post.id}
              bg={cardBg}
              p={6}
              boxShadow="md"
              borderRadius="md"
              transition="box-shadow 0.2s"
              _hover={{ boxShadow: 'lg' }}
            >
              {/* If post has a featured image */}
              <Image
                src={featuredImage}
                alt={post.title.rendered}
                borderRadius="md"
                maxH="300px"
                objectFit="cover"
                mb={4}
              />
              <Heading
                size="md"
                mb={2}
                dangerouslySetInnerHTML={{ __html: post.title.rendered }}
              />
              <Text fontSize="sm" color="gray.500" mb={3}>
                {/* Date or author if you want */}
                {new Date(post.date).toLocaleDateString()}
              </Text>
              <Box
                mb={3}
                color={textColor}
                dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
              />
              <Button
                variant={'ghost'}
                colorScheme="purple"
                onClick={() => navigate(`/blog/${post.slug}`)}
              >
                Read More
              </Button>
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
}
