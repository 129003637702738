import ReactGA from 'react-ga4';

// Page View tracking
export const trackPageView = (path) => {
  ReactGA.send({
    hitType: "pageview",
    page: path,
    title: document.title
  });
};

// User Authentication Events
export const trackAuthEvent = (eventName, userId) => {
  ReactGA.event(eventName, {
    user_id: userId
  });
};

// Plan/Subscription Events
export const trackPlanEvent = (eventName, planData) => {
  const eventData = {
    currency: planData.currency || 'USD',
    value: planData.price / 100, // Convert to decimal
    items: [{
      item_id: planData.id,
      item_name: planData.name,
      price: planData.price / 100,
      currency: planData.currency || 'USD'
    }]
  };

  // Add error data if present
  if (planData.error) {
    eventData.error_message = planData.error;
  }

  ReactGA.event(eventName, eventData);
};

// Transaction/Purchase Events
export const trackTransaction = (transactionData) => {
  ReactGA.event("purchase", {
    currency: transactionData.currency,
    transaction_id: transactionData.id,
    value: transactionData.amount / 100, // Convert to decimal
    tax: 0,
    shipping: 0,
    items: transactionData.items.map(item => ({
      item_id: item.id,
      item_name: item.name,
      price: item.price / 100,
      quantity: item.quantity || 1,
      currency: transactionData.currency
    }))
  });
};

// Generic Event Tracking
export const trackEvent = (eventName, eventData = {}) => {
  // Convert snake_case to snake_case for GA4 consistency
  const formattedEventName = eventName.toLowerCase().replace(/-/g, '_');
  ReactGA.event(formattedEventName, {
    ...eventData
  });
}; 