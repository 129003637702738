import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Image,
  useColorModeValue,
  Divider,
  Code,
  VStack,
  Icon,
  Stack,
  Grid,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaPhone, FaGlobe, FaDollarSign, FaUserShield, FaRocket, FaCogs } from 'react-icons/fa';

// Reusable MotionBox with enhanced animations
const MotionBox = motion(Box);

export default function Partners() {
  const bg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.300');
  const headingColor = useColorModeValue('gray.800', 'white');

  return (
    <Box as="section" overflow="hidden" pos="relative">
      {/* 1. HERO SECTION */}
      <Box
        pos="relative"
        bgGradient={useColorModeValue(
          'linear(to-r, purple.100, purple.200)',
          'linear(to-r, purple.700, purple.800)'
        )}
        py={{ base: 20, md: 32 }}
        px={{ base: 5, md: 16 }}
      >
        {/* Decorative Background Wave (Optional) */}
        <Box
          pos="absolute"
          top="0"
          left="0"
          w="100%"
          overflow="hidden"
          lineHeight="0"
          zIndex={-1}
        >
          <svg
            preserveAspectRatio="none"
            viewBox="0 0 1200 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: 'relative',
              display: 'block',
              width: 'calc(150% + 1.3px)',
            }}
          >
            <path
              d="M0,0 C600,120 600,0 1200,120 L1200,0 L0,0 Z"
              fill={useColorModeValue('#f7fafc', '#1A202C')}
            />
          </svg>
        </Box>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          align="center"
          justify="space-between"
          gap={{ base: 12, md: 20 }}
        >
          {/* LEFT HERO TEXT */}
          <MotionBox
            flex="1"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <Heading size="2xl" color={headingColor} mb={6}>
              Nesa Mobile Partner Program
            </Heading>
            <Text fontSize="xl" color={textColor} maxW="700px" mb={8}>
              A tailored, no-hassle global eSIM solution for businesses of all sizes.
              No sales calls needed—just sign up for your free account and start
              connecting up to 10 employees. Coverage in 185+ countries means no
              roaming fees, ever!
            </Text>
            <Stack direction={{ base: 'column', sm: 'row' }} spacing={4}>
              <Button
                colorScheme="purple"
                size="lg"
                onClick={() => {
                  // navigate to sign-up or form
                }}
                borderRadius={'full'}
              >
                Create Your Free Account
              </Button>
              <Button
                variant="outline"
                colorScheme="purple"
                size="lg"
                borderRadius={'full'}
                onClick={() => {
                  // navigate to contact or learn more
                }}
              >
                Learn More
              </Button>
            </Stack>
          </MotionBox>

          {/* RIGHT HERO IMAGE */}
          <MotionBox
            flex="1"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            display="flex"
            justifyContent="center"
          >
            <Image
              src="https://via.placeholder.com/600x400.png?text=Nesa+Partner+Hero+Image"
              alt="Nesa Partner Program Hero"
              borderRadius="md"
              boxShadow="2xl"
              maxH="400px"
              objectFit="cover"
              w="100%"
            />
          </MotionBox>
        </Flex>
      </Box>

      {/* 2. KEY BENEFITS SECTION */}
      <Box py={{ base: 16, md: 24 }} px={{ base: 5, md: 16 }} bg={bg}>
        <Heading size="lg" mb={12} textAlign="center">
          Why Choose Nesa Mobile?
        </Heading>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={16} mb={16}>
          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Icon as={FaPhone} w={12} h={12} color="purple.500" mb={4} />
            <Heading size="md" mb={3} color={headingColor}>
              No Sales Calls Needed
            </Heading>
            <Text color={textColor}>
              Get started instantly. No lengthy demos or quotes required—try it
              free for up to 10 employees.
            </Text>
          </MotionBox>

          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Icon as={FaGlobe} w={12} h={12} color="purple.500" mb={4} />
            <Heading size="md" mb={3} color={headingColor}>
              185+ Countries
            </Heading>
            <Text color={textColor}>
              Enjoy reliable data in 185+ destinations and growing—no roaming
              headaches or hidden fees.
            </Text>
          </MotionBox>

          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Icon as={FaDollarSign} w={12} h={12} color="purple.500" mb={4} />
            <Heading size="md" mb={3} color={headingColor}>
              Zero Roaming Costs
            </Heading>
            <Text color={textColor}>
              No more inflated roaming bills! Our international data coverage
              slashes travel connectivity costs by up to 70%.
            </Text>
          </MotionBox>
        </SimpleGrid>

        {/* Additional Benefits with Icons */}
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={16}>
          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Icon as={FaRocket} w={12} h={12} color="purple.500" mb={4} />
            <Heading size="md" mb={3} color={headingColor}>
              Flexible Data Plans
            </Heading>
            <Text color={textColor}>
              Choose pay-as-you-go or monthly bundles. Easily top up on-demand
              to meet business needs.
            </Text>
          </MotionBox>

          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Icon as={FaCogs} w={12} h={12} color="purple.500" mb={4} />
            <Heading size="md" mb={3} color={headingColor}>
              Easy Activation
            </Heading>
            <Text color={textColor}>
              Enable eSIM via email link or app—get your employees or customers
              online in seconds, hassle-free.
            </Text>
          </MotionBox>

          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Icon as={FaUserShield} w={12} h={12} color="purple.500" mb={4} />
            <Heading size="md" mb={3} color={headingColor}>
              Dedicated Support
            </Heading>
            <Text color={textColor}>
              We offer live assistance for all accounts—free or verified—so you
              never feel stranded.
            </Text>
          </MotionBox>
        </SimpleGrid>
      </Box>

      {/* 3. ADDITIONAL BENEFITS WITH SEPARATE CTAs */}
      {/* Example: Cost Savings */}
      <Box py={{ base: 16, md: 24 }} px={{ base: 5, md: 16 }} bg={useColorModeValue('gray.100', 'gray.700')}>
        <Heading size="lg" mb={12} textAlign="center">
          Save Big with Nesa Mobile
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={16} alignItems="center">
          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            <Image
              src="https://via.placeholder.com/500x300.png?text=Cost+Savings"
              alt="Cost Savings"
              borderRadius="md"
              boxShadow="2xl"
              objectFit="cover"
              w="100%"
            />
          </MotionBox>
          <MotionBox
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            <Heading size="lg" mb={4}>
              Slash Your Roaming Expenses
            </Heading>
            <Text fontSize="lg" color={textColor} mb={6}>
              Traditional roaming can cost your business up to $100 per employee per month.
              With Nesa Mobile, enjoy global data at a fraction of the cost. For example, switching
              from roaming fees to our eSIM solution can save you approximately $70 per employee
              each month.
            </Text>
            <Button
              colorScheme="purple"
              variant="solid"
              size="lg"
              onClick={() => {
                // navigate to sign-up or form
              }}
              borderRadius={'full'}
            >
              Start Saving Now
            </Button>
          </MotionBox>
        </SimpleGrid>
      </Box>

      {/* 4. WHO IT'S FOR SECTION */}
      <Box py={{ base: 16, md: 24 }} px={{ base: 5, md: 16 }} bg={bg}>
        <Heading size="lg" mb={6} textAlign="center">
          Perfect for a Range of Industries
        </Heading>
        <Text
          fontSize="md"
          color={textColor}
          maxW="3xl"
          mx="auto"
          mb={12}
          textAlign="center"
        >
          Whether you’re in travel, tech, or finance, Nesa Mobile's Partner
          Program can help you cut costs, generate revenue, and stay connected
          around the globe.
        </Text>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={12}>
          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Image
              src="https://via.placeholder.com/320x200.png?text=Airlines+%2F+Hotels"
              alt="Airlines & Hotels"
              borderRadius="md"
              mb={4}
            />
            <Heading size="md" mb={2} color={headingColor}>
              Airlines & Hotels
            </Heading>
            <Text color={textColor}>
              Offer guests or staff instant global data—perfect for loyalty
              programs, front-desk operations, and more.
            </Text>
          </MotionBox>

          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Image
              src="https://via.placeholder.com/320x200.png?text=Travel+Services"
              alt="Travel Services"
              borderRadius="md"
              mb={4}
            />
            <Heading size="md" mb={2} color={headingColor}>
              Travel Agencies & Transportation
            </Heading>
            <Text color={textColor}>
              Provide tourists, clients, or drivers with reliable coverage in
              185+ countries—zero roaming surprises.
            </Text>
          </MotionBox>

          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Image
              src="https://via.placeholder.com/320x200.png?text=Tech+%2F+FinTech"
              alt="Tech & Fintech"
              borderRadius="md"
              mb={4}
            />
            <Heading size="md" mb={2} color={headingColor}>
              Tech & Fintech
            </Heading>
            <Text color={textColor}>
              Keep remote or traveling teams online with secure data plans
              that don’t break your IT budget.
            </Text>
          </MotionBox>
        </SimpleGrid>
      </Box>

      {/* 5. MANAGEMENT DASHBOARD SECTION */}
      <Box py={{ base: 16, md: 24 }} px={{ base: 5, md: 16 }} bg={useColorModeValue('gray.50', 'gray.900')}>
        <Heading size="lg" mb={12} textAlign="center">
          Powerful Management Dashboard
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={16} alignItems="center">
          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            <Image
              src="/assets/images/partners/dashboard_preview.png"
              alt="Dashboard Preview"
              borderRadius="md"
              boxShadow="2xl"
              objectFit="cover"
              w="100%"
            />
          </MotionBox>
          <MotionBox
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            <Heading size="lg" mb={6}>
              All-in-One Dashboard
            </Heading>
            <Text fontSize="lg" color={textColor} mb={6}>
              Provision eSIMs, invite employees, assign data plans, and top-up
              instantly. Monitor usage in real time, track expenses, and
              generate detailed reports to measure ROI.
            </Text>
            <Button
              colorScheme="purple"
              variant="outline"
              size="lg"
              mb={6}
              onClick={() => {
                // navigate to dashboard or learn more
              }}
              borderRadius={'full'}
            >
              Explore the Dashboard
            </Button>
          </MotionBox>
        </SimpleGrid>
      </Box>

      {/* 6. API SECTION */}
      <Box py={{ base: 16, md: 24 }} px={{ base: 5, md: 16 }} bg={useColorModeValue('gray.100', 'gray.900')}>
        <Heading size="lg" mb={12} textAlign="center">
          Developer-Friendly APIs
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={16} alignItems="center">
          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            <Image
              src="https://via.placeholder.com/500x300.png?text=Dashboard+Preview"
              alt="Dashboard Preview"
              borderRadius="md"
              boxShadow="2xl"
              objectFit="cover"
              w="100%"
            />
          </MotionBox>
          <MotionBox
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            <Heading size="lg" mb={6} textAlign="left">
              Simple and Secure Integration
            </Heading>
            <Text fontSize="lg" color={textColor} mb={6} textAlign="left">
              Do everything you would with the dashboard but with our powerful APIs and SDKs.
            </Text>
            <Button
              colorScheme="purple"
              variant="outline"
              size="lg"
              mb={6}
              onClick={() => {
                // navigate to dashboard or learn more
              }}
              borderRadius={'full'}
            >
              Explore the Docs
            </Button>
            <Box
              bg={useColorModeValue('gray.50', 'gray.800')}
              p={4}
              borderRadius="md"
              boxShadow="md"
            >
              <VStack align="start" spacing={3}>
                <Code w="100%" fontSize="sm" overflow="auto">
                  {`
const nesa = createNesaClient('pk_xxxx', 'sk_xxx');
`}
                </Code>
                <Code w="100%" fontSize="sm" overflow="auto">
                  {`
const plans = nesa.plans.get();
`}
                </Code>
                <Code w="100%" fontSize="sm" overflow="auto">
                  {`
const purchase = nesa.plans.purchase('plan_xxxx');
`}
                </Code>
                <Code w="100%" fontSize="sm" overflow="auto">
                  {`
const assign = nesa.esim.assign(purchase.esim.id, 'user_xxxx');
`}
                </Code>
                <Code w="100%" fontSize="sm" overflow="auto">
                  {`
const esim = nesa.esim.get('user_xxxx');
`}
                </Code>
                <Text fontSize="sm" color={textColor}>
                  Provision a 5GB global plan in seconds—integrate and deploy
                  anywhere in your app.
                </Text>
              </VStack>
            </Box>
          </MotionBox>
        </SimpleGrid>
      </Box>

      {/* 9. ADDITIONAL CTAs & BENEFITS */}
      <Box py={{ base: 16, md: 24 }} px={{ base: 5, md: 16 }} bg={useColorModeValue('gray.100', 'gray.700')}>
        <Heading size="lg" mb={12} textAlign="center">
          Additional Perks
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={12} mb={12}>
          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ rotate: 2, scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Icon as={FaCogs} w={12} h={12} color="purple.500" mb={4} />
            <Heading size="md" mb={3} color={headingColor}>
              Tailored Solutions
            </Heading>
            <Text color={textColor}>
              Customize your connectivity solutions to fit your specific business needs.
              From unique data plans to branded eSIMs, we adapt to you.
            </Text>
          </MotionBox>

          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ rotate: -2, scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Icon as={FaUserShield} w={12} h={12} color="purple.500" mb={4} />
            <Heading size="md" mb={3} color={headingColor}>
              Verified Organization Perks
            </Heading>
            <Text color={textColor}>
              Upgrade to a verified account to unlock exclusive benefits like $100 eSIM credit,
              priority support, and more tailored services.
            </Text>
          </MotionBox>

          <MotionBox
            p={8}
            bg={bg}
            borderRadius="lg"
            boxShadow="lg"
            whileHover={{ rotate: 2, scale: 1.05, boxShadow: '2xl' }}
            transition={{ duration: 0.5 }}
            textAlign="center"
          >
            <Icon as={FaDollarSign} w={12} h={12} color="purple.500" mb={4} />
            <Heading size="md" mb={3} color={headingColor}>
              Revenue Opportunities
            </Heading>
            <Text color={textColor}>
              Monetize your platform with our Affiliate Program or expand your offerings
              through our Reseller Program—multiple streams of income await.
            </Text>
          </MotionBox>
        </SimpleGrid>
        <Box textAlign="center">
          <Button
            colorScheme="purple"
            size="lg"
            onClick={() => {
              // navigate to sign-up or form
            }}
            borderRadius={'full'}
          >
            Discover All Benefits
          </Button>
        </Box>
      </Box>

      {/* 10. FINAL CTA */}
      <Box
        py={{ base: 20, md: 32 }}
        px={{ base: 5, md: 16 }}
        bgGradient={useColorModeValue(
          'linear(to-r, purple.100, purple.200)',
          'linear(to-r, purple.700, purple.800)'
        )}
        textAlign="center"
      >
        <Heading size="lg" color={headingColor} mb={6}>
          Ready to Transform Your Connectivity?
        </Heading>
        <Text fontSize="lg" color={textColor} maxW="3xl" mx="auto" mb={12}>
          Create your free account with Nesa Mobile’s Partner Program. Experience
          global coverage, zero roaming fees, flexible plans, and dedicated
          support—no phone calls or quotes required.
        </Text>
        <Stack direction={{ base: 'column', sm: 'row' }} spacing={4} justify="center">
          <Button
            colorScheme="purple"
            size="lg"
            onClick={() => {
              // navigate to sign-up or form
            }}
            borderRadius={'full'}
          >
            Get Started Today
          </Button>
          <Button
            variant="outline"
            colorScheme="purple"
            size="lg"
            onClick={() => {
              // navigate to learn more or contact
            }}
            borderRadius={'full'}
          >
            Contact Us
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
