// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Switch, useLocation } from 'react-router-dom';
import {
  SignIn,
  SignUp,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  UserProfile,
  useUser
} from '@clerk/clerk-react';
import { Box } from '@chakra-ui/react';
import { trackPageView, trackAuthEvent } from './utils/analytics';

import Home from './pages/Home';
import About from './pages/About';
import Plans from './pages/Plans';
import Country from './components/Plans/Country';
import FAQ from './pages/FAQ';
import QuestionDetails from './pages/QuestionDetails';
import Contact from './pages/Contact';
import LandingPage from './pages/Landing';
import Error from './pages/Error';
import CouponStats from './pages/CouponStats';
import TransactionHistory from './pages/TransactionHistory';

import './App.scss';

import Header from './components/Header';
import Footer from './components/Footer';
import PrivacyPolicy from './components/Footer/privacy';
import TermsOfService from './components/Footer/terms';
import Region from './components/Plans/Region';
import Partners from './pages/Partners';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Dashboard from './pages/Dashboard';

function App() {
  const location = useLocation();
  const { user } = useUser();

  useEffect(() => {
    // Track page views
    trackPageView(location.pathname);
  }, [location]);

  useEffect(() => {
    // Track user authentication state
    if (user?.id) {
      trackAuthEvent('user_signed_in', user.id);
    }
  }, [user?.id]);

  if (location.pathname === '/help') {
    window.location.href = 'https://help.nesamobile.com/'
    return <div></div>
  }

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/plans/:countryCode" element={<Country />} />
        <Route path="/regions/:regionCode" element={<Region />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/faq/q/:questionId" element={<QuestionDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/tos" element={<TermsOfService />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/free1gb" element={<LandingPage />} />
        <Route path='/blog' element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route
          path="/partner-dashboard"
          element={
            <>
              <SignedIn>
                <CouponStats />
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/partner-dashboard/transactions"
          element={
            <>
              <SignedIn>
                <TransactionHistory />
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/sign-in/*"
          element={
            <Box
              minH="calc(100vh - 64px)"
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={8}
            >
              <SignIn
                routing="path"
                path="/sign-in"
                appearance={{
                  elements: {
                    rootBox: {
                      width: '100%',
                      maxWidth: '450px',
                      margin: '0 auto',
                    },
                    card: {
                      border: '1px solid #E2E8F0',
                      borderRadius: '1rem',
                      boxShadow: 'sm',
                    }
                  }
                }}
              />
            </Box>
          }
        />
        <Route
          path="/sign-up/*"
          element={
            <Box
              minH="calc(100vh - 64px)"
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={8}
            >
              <SignUp
                routing="path"
                path="/sign-up"
                appearance={{
                  elements: {
                    rootBox: {
                      width: '100%',
                      maxWidth: '450px',
                      margin: '0 auto',
                    },
                    card: {
                      border: '1px solid #E2E8F0',
                      borderRadius: '1rem',
                      boxShadow: 'sm',
                    }
                  }
                }}
              />
            </Box>
          }
        />
        <Route
          path="/dashboard"
          element={
            <>
              <SignedIn>
                <Dashboard />
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />
        <Route
          path="/profile"
          element={
            <>
              <SignedIn>
                <UserProfile />
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />
        {/* <Route path="/transactions" element={<TransactionHistory />} /> */}
        <Route path="/*" element={<Error />} />
      </Routes>
      <Footer />
    </div>);
}

export default App;
