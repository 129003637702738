import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text, IconButton, Link } from '@chakra-ui/react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link as ReactRouterLink } from 'react-router-dom';
import CountryFlag from './CountryFlag';

const DestinationCard = ({ code, country }) => (
  <Box
    width="100%"
    bg="gray.800"
    borderRadius="md"
    overflow="hidden"
    boxShadow="md"
    p="4"
    _hover={{ boxShadow: 'lg' }}
  >
    <Flex direction="row" align="center" justifyContent="space-between">
      <Flex direction="row" alignItems="center" gap={3}>
        <CountryFlag isoCode={code} size={32} />
        <Text fontWeight="bold" color="white" fontSize="md">
          {country}
        </Text>
      </Flex>
      <Link as={ReactRouterLink} to={`/plans/${code.toLowerCase()}`} style={{ textDecoration: 'none' }}>
        <IconButton
          icon={<IoIosArrowForward />}
          colorScheme="purple"
          aria-label="Explore"
          isRound
        />
      </Link>
    </Flex>
  </Box>
);

DestinationCard.propTypes = {
  code: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default DestinationCard;
