import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Flex,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Textarea,

} from '@chakra-ui/react';
import { FaCommentDots, FaNewspaper, FaBriefcase } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Optional: If you want a quick contact form, uncomment these imports:
// import FormControl from '@chakra-ui/react';
// import FormLabel from '@chakra-ui/react';
// import Input from '@chakra-ui/react';
// import Textarea from '@chakra-ui/react';

function Contact() {
  const bg = useColorModeValue('gray.800', 'gray.800'); // Background for the 3 boxes
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalDetails, setModalDetails] = useState({
    title: '',
    description: <Text>Hello world</Text>,
  });

  return (
    <Container maxW="container.xl" py={10} centerContent>
      {/* Modal for Support, Partnerships, or Media */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalDetails.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalDetails.description}</ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box p={5} w="full" rounded="md">
        {/* Section: Check out FAQ */}
        <Box p={5} rounded="md" textAlign="center" mb={10}>
          <Heading as="h1" size="xl">
            Need Answers Fast?
          </Heading>
          <Text mt={2}>
            Your questions might already be answered on our Frequently Asked Questions page.
            Why not check it out?
          </Text>
          <Flex justifyContent="center" mt={5}>
            <Button as={Link} to="/help" variant="solid" colorScheme="purple" mt={2}>
              Visit FAQ
            </Button>
          </Flex>
        </Box>

        {/* Section: Get in Touch */}
        <Box p={5} rounded="md" textAlign="center">
          <Heading as="h1" size="xl">
            Get in Touch
          </Heading>
          <Text mt={2}>
            Still need some help? Choose one of the options below to reach our team.
          </Text>

          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mt={10}>
            {/* Customer Support */}
            <Box p={5} bg={bg} color="white" boxShadow="xl" rounded="md">
              <Flex alignItems="center" flexDirection="column">
                <FaCommentDots size="3em" />
                <Heading as="h4" size="md" mt={4}>
                  Customer Support
                </Heading>
                <Text textAlign="center">
                  Get help with your eSIM needs.
                </Text>
                <Button
                  mt={4}
                  variant="solid"
                  leftIcon={<FaCommentDots />}
                  onClick={() => {
                    setModalDetails({
                      title: 'Customer Support',
                      description: (
                        <Text>
                          Our customer support team is available 24/7 to help with any questions
                          or concerns you may have. You can contact us via:
                          <br />
                          <br />
                          Email:&nbsp;
                          <a href="mailto:help@nesamobile.com" style={{ textDecoration: 'underline' }}>
                            help@nesamobile.com
                          </a>
                          <br />
                          WhatsApp:&nbsp;
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://wa.me/message/Z6CYYQEBRG5XM1"
                            style={{ textDecoration: 'underline' }}
                          >
                            +1 (833) 213-5678
                          </a>
                        </Text>
                      ),
                    });
                    onOpen();
                  }}
                >
                  Contact Support
                </Button>
              </Flex>
            </Box>

            {/* Business Partnerships */}
            <Box p={5} bg={bg} color="white" boxShadow="xl" rounded="md">
              <Flex alignItems="center" flexDirection="column">
                <FaBriefcase size="3em" />
                <Heading as="h4" size="md" mt={4}>
                  Business Partnerships
                </Heading>
                <Text textAlign="center">
                  Explore partnership opportunities.
                </Text>
                <Button
                  mt={4}
                  variant="solid"
                  leftIcon={<FaBriefcase />}
                  onClick={() => {
                    setModalDetails({
                      title: 'Business Partnerships',
                      description: (
                        <Text>
                          Interested in partnering with us? We offer a variety of business
                          solutions and partnership opportunities. Contact us via:
                          <br />
                          <br />
                          Email:&nbsp;
                          <a
                            href="mailto:omar@nesamobile.com?subject=Partnership with Nesa and [Your Company Here]"
                            style={{ textDecoration: 'underline' }}
                          >
                            omar@nesamobile.com
                          </a>
                          <br />
                          Calendly:&nbsp;
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://calendly.com/nesamobile/discovery"
                            style={{ textDecoration: 'underline' }}
                          >
                            Direct Meeting Link
                          </a>
                        </Text>
                      ),
                    });
                    onOpen();
                  }}
                >
                  Partner with Us
                </Button>
              </Flex>
            </Box>

            {/* Media Inquiries */}
            <Box p={5} bg={bg} color="white" boxShadow="xl" rounded="md">
              <Flex alignItems="center" flexDirection="column">
                <FaNewspaper size="3em" />
                <Heading as="h4" size="md" mt={4}>
                  Media Inquiries
                </Heading>
                <Text textAlign="center">
                  Press and media resources.
                </Text>
                <Button
                  mt={4}
                  variant="solid"
                  leftIcon={<FaNewspaper />}
                  onClick={() => {
                    setModalDetails({
                      title: 'Media Inquiries',
                      description: (
                        <Text>
                          For all media-related questions, please contact our media team.
                          <br />
                          <br />
                          Email:&nbsp;
                          <a
                            href="mailto:omar@nesamobile.com?subject=Media Inquiry"
                            style={{ textDecoration: 'underline' }}
                          >
                            omar@nesamobile.com
                          </a>
                        </Text>
                      ),
                    });
                    onOpen();
                  }}
                >
                  Contact Media Team
                </Button>
              </Flex>
            </Box>
          </SimpleGrid>
        </Box>

        {/* <Box p={5} w="full" mt={10}>
          <Heading size="md" mb={3}>Quick Contact Form</Heading>
          <FormControl mb={3}>
            <FormLabel>Name</FormLabel>
            <Input placeholder="Your Name" />
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>Email</FormLabel>
            <Input placeholder="Your Email" />
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>Message</FormLabel>
            <Textarea placeholder="How can we help you?" rows={5} />
          </FormControl>
          <Button colorScheme="purple">Send</Button>
        </Box> */}

      </Box>
    </Container>
  );
}

export default Contact;
