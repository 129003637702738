import React from 'react';
import { Box, Flex, Heading, Text, Image, SimpleGrid, } from '@chakra-ui/react';
import DestinationCard from '../Plans/DestinationCard'; // Assuming DestinationCard is in the same directory
import countryUtils from '../../utils/Plans/country';
import { useNavigate } from 'react-router-dom';

const countries = ["US", "CA", "FR", "DE", "IT", "ES", "TR", "RU"];
const destinations = countryUtils.shuffleArray(countryUtils.getCountriesFromArray(countries)); // Assuming shuffle is a function that shuffles the array

const PlanSection = () => {
  const navigate = useNavigate();
  return (
    <Box bg="gray.50" py="20">
      <Flex direction="column" align="center" maxW="1200px" mx="auto">
        <Heading size="xl" p="5" mb="3" textAlign="center">Explore Your Favorite Destinations</Heading>
        <Text fontSize="lg" mb="10" ml={5} mr={5}>Check out some of our most popular destinations</Text>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
          spacing={5}
          justifyItems="center"
        >
          {destinations.map((destination) => {
            let landmark;
            if (destination?.landmarks) {
              landmark = destination?.landmarks[Math.floor(Math.random() * destination?.landmarks.length)];
            } else {
              landmark = "";
            }
            return (
              <DestinationCard
                key={destination.code}
                code={destination.code}
                country={destination.name}
                price={destination.price}
                landmarkUrl={process.env.PUBLIC_URL + '/assets/images/landmarks/' + landmark + '.png'}
              />);
          })}
        </SimpleGrid>
      </Flex>
    </Box>
  )
};

export default PlanSection;
