// src/components/Home/MidCTASection.js

import React from 'react';
import { Box, Flex, Heading, Text, Button, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const MidCTASection = () => {
  const navigate = useNavigate();
  const bg = useColorModeValue('white', 'gray.700');

  return (
    <Box bg={bg} py="16" px="6">
      <Flex
        direction="column"
        align="center"
        textAlign="center"
        maxW="800px"
        mx="auto"
      >
        <Heading size="xl" mb="4">
          Ready to Ditch Roaming Fees Forever?
        </Heading>
        <Text fontSize="lg" mb="8">
          Join thousands of travelers who are saying goodbye to outrageous roaming charges and hello to affordable global data.
        </Text>
        <Button
          colorScheme="purple"
          size="lg"
          onClick={() => {
            navigate('/plans');
          }}
          borderRadius={'5rem'}
        >
          Get Your eSIM
        </Button>
      </Flex>
    </Box>
  );
};

export default MidCTASection;
