import React from 'react';
import { Box, Flex, Heading, Text, Image, Icon } from '@chakra-ui/react';
import { MdOutlinePhonelinkSetup, MdModeOfTravel, MdDataUsage, MdCardGiftcard, MdHeadsetMic } from "react-icons/md";
import { TbHandClick } from "react-icons/tb";

import { AiFillThunderbolt } from "react-icons/ai";
import { IoShieldSharp } from "react-icons/io5";
import { TbWorldCheck } from "react-icons/tb";
import { FaRectangleList } from "react-icons/fa6";

const FeatureSection = () => (
  <Box bg="gray.50" py="20">
    <Flex direction="column" align="center" maxW="1200px" mx="auto">
      <Heading size="xl" p="5" mb="10">Why Choose Nesa Mobile?</Heading>
      <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" w="100%">
        <Box flex="1" textAlign="center" p="5">
          <Icon as={AiFillThunderbolt} w={50} h={50} color="purple.700" />
          <Heading size="md" mt="4">Instant Activation</Heading>
          <Text mt="2">Up and running in under two minutes — no paperwork, no lines.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Icon as={IoShieldSharp} w={50} h={50} color="purple.700" />
          <Heading size="md" mt="4">Zero Hidden Fees</Heading>
          <Text mt="2">Enjoy transparent pay-as-you-go or unlimited data plans — no surprise charges.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Icon as={TbWorldCheck} w={50} h={50} color="purple.700" />
          <Heading size="md" mt="4">Unrivaled Coverage</Heading>
          <Text mt="2">Stay connected in over 185+ countries and regions — urban hubs or remote getaways.</Text>
        </Box>
      </Flex>
      <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" w="100%">
        <Box flex="1" textAlign="center" p="5">
          <Icon as={FaRectangleList} w={50} h={50} color="purple.700" />
          <Heading size="md" mt="4">Data Plans for Every Lifestyle</Heading>
          <Text mt="2">Daily, weekly, monthly, or custom — choose what works for you.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Icon as={MdCardGiftcard} w={50} h={50} color="purple.700" />
          <Heading size="md" mt="4">Exclusive Perks</Heading>
          <Text mt="2">Earn NesaPoints with every purchase and redeem them for discounts on future adventures.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Icon as={MdHeadsetMic} w={50} h={50} color="purple.700" />
          <Heading size="md" mt="4">24/7 Support</Heading>
          <Text mt="2">Our dedicated support team is here to help you anytime, anywhere.</Text>
        </Box>
      </Flex>
    </Flex>
  </Box>
);

export default FeatureSection;
