// src/pages/Region.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Image,
  Heading,
  Text,
  Stack,
  Grid,
  Button,
  useBreakpointValue,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  Tab,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useColorModeValue,
  Select,
  Divider
} from '@chakra-ui/react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link as ReactRouterLink } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';

import ApiClient from '../../utils/API/api';
import { filterAndSortForRegion } from '../../utils/Plans/plans';
import PlanCard from '../Plans/PlanCard';
import countryImagesMapping from './countryImagesMapping'; // A new mapping for region images (optional)

// Example utility if you want region-friendly names
const getRegionName = (code) => {
  switch (code?.toUpperCase()) {
    case 'EU':
      return 'Europe';
    case 'NA':
      return 'North America';
    case 'SA':
      return 'South America';
    case 'AS':
      return 'Asia';
    case 'CB':
      return 'Caribbean';
    case 'ME':
      return 'Middle East';
    case 'AF':
      return 'Africa';
    default:
      return 'Unknown Region';
  }
};

const getRegionDivisionLabel = (location) => {
  if (!location) return '';
  const countries = location.split(',');
  return `${countries.length} Countries`;
};

const Region = () => {
  const { regionCode } = useParams();            // e.g., "EU" or "NA"
  const regionName = getRegionName(regionCode);  // e.g., "Europe"

  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });

  // For theming
  const containerBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const bg = useColorModeValue('gray.100', 'gray.700');

  const [isLoading, setIsLoading] = useState(true);
  const [regionPlans, setRegionPlans] = useState([]);
  const [sortOption, setSortOption] = useState('');

  // Tab segmentation (like country)
  const [segments, setSegments] = useState([
    { label: 'Reloadable', shown: false },
    { label: 'Non-reloadable', shown: false },
    { label: 'Unlimited', shown: false },
  ]);

  // OPTIONAL: If you have regionImagesMapping (similar to countryImagesMapping)
  const regionImageData = countryImagesMapping.find((r) => r.region === regionName);

  // Add these new states
  const [selectedDivision, setSelectedDivision] = useState('');
  const [regionDivisions, setRegionDivisions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => '');
        const response = await apiClient.get('/plans');

        // Filter for all region-based plans (location === regionCode.toUpperCase())
        const allRegionPlans = response.data.obj.packageList.filter(
          (plan) => plan.slug.split('-')[0] === regionCode.toUpperCase()
        );

        // Get unique divisions based on location field
        const uniqueDivisions = [...new Set(allRegionPlans.map(plan => plan.location))];
        const divisionOptions = uniqueDivisions.map(division => ({
          label: getRegionDivisionLabel(division),
          value: division,
          countryCount: division.split(',').length
        })).sort((a, b) => a.countryCount - b.countryCount);

        setRegionDivisions(divisionOptions);
        // Set default to first division
        if (divisionOptions.length > 0) {
          setSelectedDivision(divisionOptions[0].value);
        }

        const sorted = filterAndSortForRegion(allRegionPlans, regionCode.toUpperCase());
        setRegionPlans(sorted);

        // Determine which segments are shown (just like country)
        allRegionPlans.forEach((plan) => {
          if (!plan.slug.endsWith('_Daily') && !plan.packageCode.endsWith('_End')) {
            setSegments((prev) => {
              const updated = [...prev];
              updated[0].shown = true; // Reloadable
              return updated;
            });
          }
          if (plan.slug.endsWith('_End')) {
            setSegments((prev) => {
              const updated = [...prev];
              updated[1].shown = true; // Non-reloadable
              return updated;
            });
          }
          if (plan.slug.endsWith('_Daily')) {
            setSegments((prev) => {
              const updated = [...prev];
              updated[2].shown = true; // Unlimited
              return updated;
            });
          }
        });

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching region plans:', error);
      }
    };

    fetchData();
  }, [regionCode]);

  // If no plans at all
  if (!isLoading && regionPlans.length === 0) {
    return (
      <Box py="5" px="10">
        <Flex direction="column" align="center" maxW="1200px" mx="auto">
          <Heading size="lg" mb="6">Coming Soon</Heading>
          <Text fontSize="md">
            We're currently working to provide plans in {regionName}. Please check back soon!
          </Text>
        </Flex>
      </Box>
    );
  }

  // Add this sorting function
  const sortPlans = (plans, sortOption) => {
    const sortedPlans = [...plans];
    switch (sortOption) {
      case 'duration':
        return sortedPlans.sort((a, b) => {
          if (a.duration !== b.duration) {
            return a.duration - b.duration;
          }
          return a.volume - b.volume;
        });
      case 'dataHighLow':
        return sortedPlans.sort((a, b) => b.volume - a.volume);
      case 'dataLowHigh':
        return sortedPlans.sort((a, b) => a.volume - b.volume);
      case 'priceLowHigh':
        return sortedPlans.sort((a, b) => a.price - b.price);
      case 'priceHighLow':
        return sortedPlans.sort((a, b) => b.price - a.price);
      default:
        return sortedPlans;
    }
  };

  // Update the sort options in both Region.js and Country.js
  const sortOptions = [
    { value: 'duration', label: 'Duration' },
    { value: 'dataLowHigh', label: 'Data: Low to High' },
    { value: 'dataHighLow', label: 'Data: High to Low' },
    { value: 'priceLowHigh', label: 'Price: Low to High' },
    { value: 'priceHighLow', label: 'Price: High to Low' },
  ];

  // Update the filtering logic
  const getFilteredAndSortedPlans = (segmentLabel) => {
    let filtered = regionPlans;

    // Filter by division if selected
    if (selectedDivision) {
      filtered = filtered.filter(plan => plan.location === selectedDivision);
    }

    // Filter by plan type
    filtered = filtered.filter(plan => {
      if (segmentLabel === 'Reloadable') {
        return !plan.slug.endsWith('_Daily') && !plan.slug.endsWith('_End');
      }
      if (segmentLabel === 'Non-reloadable') {
        return plan.slug.endsWith('_End');
      }
      if (segmentLabel === 'Unlimited') {
        return plan.slug.endsWith('_Daily');
      }
      return true;
    });

    // Sort the filtered plans
    return sortPlans(filtered, sortOption);
  };

  // Update the render function
  const renderPlans = (segmentLabel) => {
    const filteredAndSorted = getFilteredAndSortedPlans(segmentLabel);

    if (!filteredAndSorted.length) {
      return (
        <Box p={4} textAlign="center">
          <Text>No plans available for this selection</Text>
        </Box>
      );
    }

    return (
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)"
        }}
        gap={6}
      >
        {filteredAndSorted.map((plan) => (
          <PlanCard key={plan.packageCode} plan={plan} />
        ))}
      </Grid>
    );
  };

  if (isLoading) {
    return (
      <Box py="20" px="10">
        <Flex direction="column" align="center" maxW="1200px" mx="auto">
          <Heading size="xl" mb="10">Loading...</Heading>
        </Flex>
      </Box>
    );
  }

  return (
    <Box py="5" px="10">
      {/* Breadcrumb */}
      <Flex gap={5} mb={10} maxW="1200px" mx="auto" align="center">
        <Breadcrumb spacing="8px" separator={<RiArrowRightSLine color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink as={ReactRouterLink} to="/plans">
              Plans
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{regionName}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        gap={8}
        maxW="1200px"
        mx="auto"
        my="5"
        bg={containerBg}
        borderRadius="xl"
        p={{ base: 5, md: 10 }}
        boxShadow="md"
      >
        {/* Region Image (similar to country) */}
        {!isMobile && regionImageData?.fileName && (
          <Box
            flex="1"
            pos="relative"
            maxW="400px"
            w="100%"
            h="auto"
          >
            <Image
              w="100%"
              maxH="300px"
              objectFit="cover"
              src={regionImageData.fileName}
              alt={regionName}
              borderRadius="2xl"
            />
            <Box
              pos="absolute"
              bottom="0"
              w="100%"
              p="3"
              bg="rgba(0, 0, 0, 0.7)"
              borderBottomRadius="2xl"
            >
              <Text fontSize="xs" color="white">
                Photo by{' '}
                <a
                  style={{ textDecoration: 'underline', color: 'white' }}
                  target="_blank"
                  rel="noreferrer"
                  href={regionImageData.photographerProfile}
                >
                  {regionImageData.photographerName}
                </a>{' '}
                from{' '}
                <a
                  style={{ textDecoration: 'underline', color: 'white' }}
                  target="_blank"
                  rel="noreferrer"
                  href={regionImageData.unsplashLink}
                >
                  Unsplash
                </a>
              </Text>
            </Box>
          </Box>
        )}

        <Box flex="2">
          <Flex justify="space-between" align="center" mb={4}>
            <Heading size="lg">
              Stay Connected Across {regionName}
            </Heading>

            {regionDivisions.length > 1 && (
              <Flex align="center" gap={2}>
                <Text fontWeight="medium" color={textColor} whiteSpace="nowrap">
                  Coverage Area:
                </Text>
                <Select
                  icon={<FaChevronDown />}
                  value={selectedDivision}
                  onChange={(e) => setSelectedDivision(e.target.value)}
                  width="160px"
                  size="sm"
                >
                  {regionDivisions.map((division) => (
                    <option key={division.value} value={division.value}>
                      {division.label}
                    </option>
                  ))}
                </Select>
              </Flex>
            )}
          </Flex>

          <Text fontWeight="500" color={textColor} mb={4}>
            {selectedDivision && (
              <>
                Connect seamlessly across {getRegionDivisionLabel(selectedDivision)} in {regionName}.
                Perfect for multi-country travel.
              </>
            )}
          </Text>

          <Divider my={4} />

          {/* Sorting */}
          <Flex alignItems="center" gap={3} mb={4} flexWrap="wrap">
            <Text fontWeight="medium" color={textColor}>
              Sort By:
            </Text>
            <Select
              width="200px"
              onChange={(e) => setSortOption(e.target.value)}
              value={sortOption}
              size="sm"
              icon={<FaChevronDown />}
            >
              {sortOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Flex>

          {/* Plan Type Tabs */}
          <Stack spacing="8" w="100%">
            <Tabs variant="soft-rounded" colorScheme="purple" mt={4}>
              <TabList flexWrap="wrap" overflowX="auto">
                {segments
                  .filter((segment) => segment.shown)
                  .map((segment) => (
                    <Tab
                      key={segment.label}
                      whiteSpace="nowrap"
                      _hover={{ bg }}
                    >
                      {segment.label}
                    </Tab>
                  ))}
              </TabList>
              <TabPanels>
                {segments
                  .filter((segment) => segment.shown)
                  .map((segment) => (
                    <TabPanel key={segment.label} pt={6} px={0}>
                      {/* Explanatory text, as requested in Country page */}
                      <Text fontSize="sm" mb="4" color={textColor}>
                        {segment.label === 'Reloadable' &&
                          "Flexible eSIMs you can recharge anytime to stay connected."}
                        {segment.label === 'Non-reloadable' &&
                          "One-time eSIMs perfect for short trips or specific needs."}
                        {segment.label === 'Unlimited' &&
                          "Select the amount of high-speed data you want daily, then continue at lower speeds afterward."}
                      </Text>
                      {renderPlans(segment.label)}
                    </TabPanel>
                  ))}
              </TabPanels>
            </Tabs>

            {/* CTA to go back to main Plans */}
            <Button
              mt="4"
              colorScheme="purple"
              onClick={() => navigate('/plans')}
              alignSelf="flex-start"
              borderRadius={'full'}
              size={'lg'}
            >
              View All Plans
            </Button>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};

export default Region;
